import { OrderStatus } from "@appTypes/Enums";

export default function convertOrderStatus(type: OrderStatus | ""): string {
  const converter: Record<OrderStatus | "", string> = {
    archive: "Удален/Архив",
    draft: "Черновик",
    error: "Найдены ошибки в документах",
    success: "Успешно проверено",
    verification: "Отправлено на проверку",
    work: "В работе",
    "": "",
  };

  return converter[type] ?? "";
}
