import { ButtonsBlockProps } from "@components/ButtonsBlock/ButtonsBlockProps";

import { applicationAPI } from "@api/api";
import { UserRoles } from "@appTypes/Enums";
import { useAppSelector } from "@hooks/redux";
import findLatestUpdateStatus from "@utils/findLatestUpdateStatus";

interface ButtonsBlockConfig {
  pending: ButtonsBlockProps["config"];
  needEdit: ButtonsBlockProps["config"];
  fullfilled: ButtonsBlockProps["config"];
}

const isEditBtnDisabled = (): boolean => {
  const isCorrectRole = useAppSelector(
    (state) =>
      ![UserRoles.agent, UserRoles.agentPremium].includes(
        state.user.role as UserRoles
      )
  );
  const isCorrectStatus = useAppSelector(
    (state) =>
      !["draft", "error"].includes(
        findLatestUpdateStatus(state.orders.chosenOrder)
      )
  );

  const result = isCorrectRole || isCorrectStatus;
  return result;
};

export const getButtonsBlockConfig = (id: string): ButtonsBlockConfig => ({
  pending: [
    {
      label: "Вернуться",
      navigateTo: "../../main/task",
    },
    {
      label: "Удалить",
      onClick: () => applicationAPI.delete(Number(id)),
      styleType: "line",
      navigateTo: "../../main/task",
    },
  ],
  needEdit: [
    {
      label: "Редактировать",
      disabled: isEditBtnDisabled(),
      navigateTo: `../../request-edit/${id}`,
    },
    {
      label: "Вернуться",
      styleType: "line",
      navigateTo: "../../main/task",
    },
  ],
  fullfilled: [
    {
      label: "Вернуться",
      navigateTo: "../../main/task",
    },
  ],
});
