import React, { useState, useEffect, ChangeEvent } from "react";
import { shallowEqual } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ButtonsBlockProps } from "@components/ButtonsBlock/ButtonsBlockProps";

import { resetAllOrders } from "@store/reducers/order/OrderSlice";
import { applicationAPI } from "@api/api";
import { useAppSelector } from "@hooks/redux";
import { OrderStatus } from "@appTypes/Enums";
import isOrderFilled from "@utils/isOrderFilled";
import useRequestStatusBlockFacade from "@components/RequestStatusBlock/useRequestStatusBlock";
import { dispatch } from "@store/index";

type ButtonConfig = Record<"edit" | "new", ButtonsBlockProps["config"]>;

export default function useNewRequestFacade(): [
  string,
  IRequest,
  IEditableRequest,
  ButtonsBlockProps["config"],
  (event: React.ChangeEvent<HTMLInputElement>) => void
] {
  const id = useParams().id ?? undefined;
  const isNewOrder = !id;
  const newOrderId = useAppSelector(
    (state) => state.orders.newOrder?.id,
    shallowEqual
  );
  const order = useAppSelector(({ orders }) =>
    isNewOrder ? orders.newOrder : orders.chosenOrder
  );

  const role = useAppSelector((state) => state.user.role);

  const [status] = useRequestStatusBlockFacade();

  const isAllowDelete =
    (role.includes("dsa_administrator") || role.includes("dsa_supervisor")) &&
    (status === "verification" || status === "draft");

  const isAllowSend =
    role.includes("dsa_administrator") ||
    role.includes("dsa_agent") ||
    role.includes("dsa_agentpremium");

  useEffect(() => {
    console.log(role, isAllowSend);
  }, [role]);

  const getOnVerifBtnClick = (innerId: string | number) => () => {
    applicationAPI.verification(Number(innerId));
  };

  const getOnDeleteBtnClick = (innerId: string | number) => () => {
    applicationAPI.delete(Number(isNewOrder ? newOrderId : id));
  };

  const [buttonConfig, setButtonConfig] = useState<ButtonConfig>({
    new: [
      {
        label: "Отправить на проверку",
        navigateTo: "../main/task",
        disabled: !isAllowSend || !isOrderFilled(isNewOrder ? "new" : "chosen"),
        onClick: getOnVerifBtnClick(
          isNewOrder ? (newOrderId as number) : (id as string)
        ),
      },
      {
        label: "Удалить",
        styleType: "line",
        disabled: !isAllowDelete,
        navigateTo: "../main/task",
        onClick: getOnDeleteBtnClick(
          isNewOrder ? (newOrderId as number) : (id as string)
        ),
      },
      {
        label: "Вернуться",
        styleType: "line",
        onClick: () => dispatch(resetAllOrders()),
        navigateTo: "../main/task",
      },
    ],
    edit: [
      {
        label: "Отправить на проверку",
        navigateTo: "../main/task",
        disabled: !isOrderFilled(isNewOrder ? "new" : "chosen"),
        onClick: () =>
          applicationAPI.verification(Number(isNewOrder ? newOrderId : id)),
      },
      {
        label: "Удалить",
        styleType: "line",
        navigateTo: "../main/task",
      },
      {
        label: "Вернуться",
        styleType: "line",
        onClick: () => dispatch(resetAllOrders()),
        navigateTo: "../../main/task",
      },
    ],
  });

  const navigateTo = useNavigate();

  const newOrder = useAppSelector((state) => state.orders.newOrder);

  const chosenOrder = useAppSelector((state) =>
    state.orders.orders.filter((order) => String(order.id) === id)
  )[0];

  const [chosenOrderStatus, setChosenOrderStatus] = useState("");

  const [data, setData] = useState<IEditableRequest>({
    fio: id ? chosenOrder?.fio : "",
    birthdate: id ? chosenOrder?.birthdate : "",
    organization: id ? chosenOrder?.organization : { name: "", inn: "" },
    inn: id ? chosenOrder?.inn : "",
    dateDepart: id ? chosenOrder?.dateDepart : "",
    resident: id ? chosenOrder?.resident : true,
    comments: id ? chosenOrder?.comments : "",
    attache: id ? chosenOrder?.attache : [],
    status: id
      ? (chosenOrder?.status as OrderStatus)
      : (newOrder?.status as OrderStatus),
  });

  useEffect(() => {
    setData({ ...chosenOrder });
    if (chosenOrder)
      setChosenOrderStatus(
        chosenOrder?.statuses[chosenOrder.statuses.length - 1]?.status
      );
  }, [chosenOrder]);

  useEffect(() => {
    const newButtonConfig = { ...buttonConfig };
    newButtonConfig[isNewOrder ? "new" : "edit"] = newButtonConfig.new.map(
      (item: ButtonsBlockProps["config"][number]) => {
        if (item.label === "Отправить на проверку") {
          const newItem = { ...item };
          newItem.disabled =
            !isAllowSend || !isOrderFilled(isNewOrder ? "new" : "chosen");

          newItem.onClick = getOnVerifBtnClick(
            isNewOrder ? (newOrderId as number) : (id as string)
          );
          return newItem;
        }
        if (item.label === "Удалить") {
          const newItem = { ...item };

          newItem.onClick = getOnDeleteBtnClick(
            isNewOrder ? (newOrderId as number) : (id as string)
          );
          return newItem;
        }
        return item;
      }
    );
    setButtonConfig(newButtonConfig);
  }, [order, id, newOrderId]);

  const changeData = (e: ChangeEvent<HTMLInputElement>): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [e.target.name]: e.target.value,
      };
    });
  };

  return [
    chosenOrderStatus,
    chosenOrder,
    data,
    isNewOrder ? buttonConfig.new : buttonConfig.edit,
    changeData,
  ];
}
