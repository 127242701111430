import { useAppSelector } from "@hooks/redux";
import React from "react";

import "./UserInfoBlock.scss";

export const UserInfoBlock: React.FC = function (): JSX.Element {
  const agentFIO = useAppSelector(
    (state) => state.orders.chosenOrder?.agent?.fio
  );
  return (
    <div className="agent-info">
      <p>ФИО Агента</p>
      <p>{agentFIO}</p>
    </div>
  );
};
