import React from "react";
import { Row, Col } from "react-bootstrap";
import Button from "mcb-uikit/RectButton/index";
import Selector from "mcb-uikit/MultiSelectReact/index";

import { ButtonsBlock } from "@components/ButtonsBlock/ButtonsBlock";
import { MainFilterPanelProps } from "./MainFilterPanelProps";

import useMainFilterPanelFacade from "./useMainFilterPanelFacade";

import "./MainFilterPanel.scss";

export const MainFilterPanel: React.FC<MainFilterPanelProps> = function ({
  buttonsConfig,
  type,
}): JSX.Element {
  const [page, getPageSetter, setAscending] = useMainFilterPanelFacade();
  return (
    <Row className="main-filter-panel">
      <Col className="filter_title_wrapper">
        <Selector disabled placeholder="Фильтровать по" />
      </Col>
      {type === "orders" && (
        <Col className="pagination-module">
          <Button
            disabled={page === 1}
            onClick={getPageSetter("decrease")}
            styleType="line"
          >
            Назад
          </Button>
          <span>{page}</span>
          <Button onClick={getPageSetter("increase")} styleType="line">
            Вперед
          </Button>
        </Col>
      )}
      <Col className="btn_wrapper">
        <ButtonsBlock config={buttonsConfig} />
      </Col>
    </Row>
  );
};
