import { useEffect, useState } from "react";
import {
  setUserRole,
  setUsername,
  setUserLogin,
} from "@store/reducers/user/UserSlice";
import { keycloak } from "@utils/keycloakInstance";
import formatUsername from "@utils/foramtUsername";
import { keycloakToken } from "@store/reducers/auth/AuthSlice";
import axios from "@api/index";
import findUserRole from "@utils/findUserRole";
import { dispatch } from "./store/index";

import { redirectUri } from "../configs/keyclock/keyclok-config";

export default function useAppFacade(): [boolean, any] {
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [keyCloakError, setKeyCloakError] = useState();
  const initKeycloak = (): void => {
    if (!keycloak.authenticated)
      keycloak
        .init({
          onLoad: "login-required",
          redirectUri,
          checkLoginIframe: false,
        })
        .then(() => {
          setIsAuthorised(true);
          dispatch(keycloakToken(keycloak.token as string));
          dispatch(
            setUserRole(
              findUserRole(keycloak.tokenParsed?.realm_access?.roles ?? [])
            )
          );
          dispatch(
            setUsername(formatUsername(keycloak.tokenParsed?.displayName ?? ""))
          );
          dispatch(
            setUserLogin(keycloak.tokenParsed?.preferred_username ?? "")
          );
        })
        .catch((error) => setKeyCloakError(error));
    else {
      setIsAuthorised(true);
      dispatch(keycloakToken(keycloak.token as string));
      dispatch(setUserRole(keycloak.tokenParsed?.preferred_username ?? []));
      dispatch(
        setUsername(formatUsername(keycloak.tokenParsed?.displayName ?? ""))
      );
      dispatch(setUserLogin(keycloak.tokenParsed?.preferred_username ?? ""));
    }
  };

  useEffect(() => {
    initKeycloak();
  }, []);

  return [isAuthorised, keyCloakError];
}
