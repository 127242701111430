import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@hooks/redux";

import {
  updateChosenOrder,
  updateNewOrder,
} from "@store/reducers/order/OrderSlice";
import { dispatch } from "@store/index";

export default function useClientTypeRadioGroupFacade(): [
  boolean,
  (event: React.ChangeEvent<HTMLInputElement>) => void
] {
  const isNewOrder = !useParams().id;

  const dispatchWithAction = (payload: Partial<IRequest>): void => {
    const actionCreator = isNewOrder ? updateNewOrder : updateChosenOrder;
    dispatch(actionCreator(payload));
  };

  const chosenOrder = useAppSelector(
    (state) => state.orders.chosenOrder
  ) as IRequest;

  const [isResident, setIsResident] = useState<boolean>(
    isNewOrder ? true : chosenOrder?.resident
  );

  useEffect(() => {
    if (isNewOrder) dispatchWithAction({ resident: true });
  }, []);

  const onRadioGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const resident = Boolean(event.target.value);
    setIsResident(resident);
    dispatchWithAction({ resident });
  };

  return [isResident, onRadioGroupChange];
}
