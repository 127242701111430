import React, { useEffect, useState } from "react";

import srcVideo from "@resources/testVideo.webm";

export default (): [
  React.RefObject<HTMLVideoElement>,
  React.RefObject<HTMLCanvasElement>,
  string[],
  boolean,
  boolean,
  string[],
  React.Dispatch<React.SetStateAction<string[]>>,
  React.Dispatch<React.SetStateAction<boolean>>,
  React.Dispatch<React.SetStateAction<boolean>>,
  React.Dispatch<React.SetStateAction<string[]>>,
  () => void,
  () => void
] => {
  const videoRef = React.createRef<HTMLVideoElement>();
  const canvasRef = React.createRef<HTMLCanvasElement>();

  const [photos, setterPhotos] = useState<string[]>([]);
  const [isHaveVideo, setIsHaveVideo] = useState(false);
  const [isShowPhotos, setIsShowPhotos] = useState(false);
  const [confirmedPhotos, setConfirmedPhotos] = useState<string[]>([]);

  let height = 0;
  const width = 320;

  const setPhotos = (value: string): void => setterPhotos([...photos, value]);
  const confirmAllPhotos = (): void => setConfirmedPhotos([...photos]);

  const getVideoSource = async (): Promise<any> => {
    // const src = await navigator.mediaDevices
    //   .getUserMedia({
    //     video: { facingMode: { exact: "environment" } },
    //   })
    //   .then((mediaStream) => mediaStream)
    //   .catch(() => srcVideo);
    (videoRef.current as HTMLVideoElement).src = srcVideo;
  };

  const takePicture = (): void => {
    const context = (canvasRef.current as HTMLCanvasElement).getContext(
      "2d"
    ) as CanvasRenderingContext2D;
    const videoCurr = videoRef.current as HTMLVideoElement;
    const canvasCurr = canvasRef.current as HTMLCanvasElement;
    height = videoCurr.videoHeight / (videoCurr.videoWidth / width);
    if (width && height) {
      canvasCurr.width = width;
      canvasCurr.height = height;
      context.drawImage(videoCurr, 0, 0, width, height);

      const data = canvasCurr.toDataURL("image/png");
      setPhotos(data);
    }
  };

  useEffect(() => {
    getVideoSource();
  }, [isShowPhotos]);

  return [
    videoRef,
    canvasRef,
    photos,
    isHaveVideo,
    isShowPhotos,
    confirmedPhotos,
    setterPhotos,
    setIsHaveVideo,
    setIsShowPhotos,
    setConfirmedPhotos,
    confirmAllPhotos,
    takePicture,
  ];
};
