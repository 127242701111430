/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { organizationAPI } from "@api/api";

export interface OrganisationsPaginationProperties {
  page: number;
  sortby: string; // keyof IRequest;
  ascending: boolean;
}

interface OrganizationState {
  organizations: IOrganization[] | [];
  organization: IOrganization | undefined;
  chosenOrganization: IOrganization | undefined;
  newOrganization: INewOrganization | undefined;
  notification: string | undefined;
  organisationsPaginationProperties: OrganisationsPaginationProperties;
}

const paginationInitState: OrganisationsPaginationProperties = {
  page: 1,
  sortby: "id",
  ascending: false,
};

const initialState: OrganizationState = {
  organizations: [],
  organization: undefined,
  chosenOrganization: undefined,
  newOrganization: undefined,
  notification: "",
  organisationsPaginationProperties: { ...paginationInitState },
};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizations(state, action: PayloadAction<IOrganization[]>) {
      state.notification = "";
      state.organizations = action.payload;
    },
    setOrganization(state, action: PayloadAction<IOrganization>) {
      state.notification = "";
      state.organization = action.payload;
    },
    setChosenOrganization(state, action: PayloadAction<IOrganization>) {
      state.chosenOrganization = action.payload;
    },
    organizationError(state, action: PayloadAction<string>) {
      state.notification = action.payload;
      state.organizations = [];
    },
    organizationErrorClear(state, action: PayloadAction<string>) {
      state.notification = action.payload;
    },
    updateNewOrganization(
      state,
      action: PayloadAction<Partial<INewOrganization>>
    ) {
      if (!state.newOrganization) {
        state.newOrganization = action.payload as INewOrganization;
        return state;
      }
      state.newOrganization = { ...state.newOrganization, ...action.payload };
      return state;
    },
    updateChosenOrganization(
      state,
      action: PayloadAction<
        Partial<Pick<IOrganization, "address" | "inn" | "name">>
      >
    ) {
      if (!state.chosenOrganization) {
        state.chosenOrganization = action.payload as IOrganization;
        return state;
      }
      state.chosenOrganization = {
        ...state.chosenOrganization,
        ...action.payload,
      };
      return state;
    },
    resetAllOrganizations(state, action: PayloadAction) {
      const currentPagProps = { ...state.organisationsPaginationProperties };
      state = { ...initialState, ...currentPagProps };
      return state;
    },
    setOrgPaginationProperties(
      state,
      action: PayloadAction<Partial<OrganisationsPaginationProperties>>
    ) {
      state.organisationsPaginationProperties = {
        ...state.organisationsPaginationProperties,
        ...action.payload,
      };
      organizationAPI.getAll(state.organisationsPaginationProperties);
    },
  },
});

export const {
  setOrganizations,
  organizationError,
  organizationErrorClear,
  setOrganization,
  setChosenOrganization,
  updateChosenOrganization,
  updateNewOrganization,
  resetAllOrganizations,
  setOrgPaginationProperties,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;
