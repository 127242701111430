/* eslint-disable camelcase */
import React from "react";

import { ClientDocBlockProps, ClientDocLabels } from "./ClientDocBlockProps";

import { useAppSelector } from "../../hooks/redux";

import useClientDocBlockFacade from "./useClientDocBlockFacade";

import "./ClientDocBlock.scss";

export const ClientDocBlock: React.FC<ClientDocBlockProps> =
  function (): JSX.Element {
    const [docData, isResident] = useClientDocBlockFacade();

    return (
      <div className="client-doc">
        <div className="info-doc">
          <span>Разворот паспорта</span>
          <span>{docData.passport_main.title}</span>
          {docData.passport_main.comment && (
            <span>Комментарий: {docData.passport_main.comment}</span>
          )}
        </div>
        {isResident ? (
          <>
            <div className="info-doc">
              <span>Страница регистрации</span>
              <span>{docData.passport_registration.title}</span>
              {docData.passport_registration.comment && (
                <span>
                  Комментарий: {docData.passport_registration.comment}
                </span>
              )}
            </div>
            <div className="info-doc">
              <span>Предыдущие паспорта</span>
              <span>{docData.passport_previous.title}</span>
              {docData.passport_previous.comment && (
                <span>Комментарий: {docData.passport_previous.comment}</span>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="info-doc">
              <span>Виза</span>
              <span>{docData.visa.title}</span>
              {docData.visa.comment && (
                <span>Комментарий: {docData.visa.comment}</span>
              )}
            </div>
            <div className="info-doc">
              <span>Миграционная карта</span>
              <span>{docData.migration_card.title}</span>
              {docData.migration_card.comment && (
                <span>Комментарий: {docData.migration_card.comment}</span>
              )}
            </div>
            <div className="info-doc">
              <span>Вид на жительство</span>
              <span>{docData.residence_permit.title}</span>
              {docData.residence_permit.comment && (
                <span>Комментарий: {docData.residence_permit.comment}</span>
              )}
            </div>
            <div className="info-doc">
              <span>Разрешение на временное проживание</span>
              <span>{docData.temporary.title}</span>
              {docData.temporary.comment && (
                <span>Комментарий: {docData.temporary.comment}</span>
              )}
            </div>
          </>
        )}
        <div className="info-doc">
          <span>Заявление ДБО</span>
          <span>{docData.dbo_statement.title}</span>
          {docData.dbo_statement.comment && (
            <span>Комментарий: {docData.dbo_statement.comment}</span>
          )}
        </div>
        {(Object.keys(docData) as DocumentTypes[])
          .filter((key) => key.includes("another_document"))
          .map((key) => (
            <div className="info-doc">
              <span>Другие документы</span>
              <span>{docData[key].title}</span>
              {docData[key].comment && (
                <span>Комментарий: {docData[key].comment}</span>
              )}
            </div>
          ))}
      </div>
    );
  };
