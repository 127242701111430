import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { RequestTitle } from "@components/RequestTitle/RequestTitle";
import { LogoutPanel } from "@components/LogoutPanel/LogoutPanel";
import { RequestStatusBlock } from "@components/RequestStatusBlock/RequestStatusBlock";
import { SupervizerFormBlock } from "@components/SupervizerFormBlock/SupervizerFormBlock";
import { UserInfoBlock } from "@components/UserInfoBlock/UserInfoBlock";
import { RequestStatusLog } from "@components/RequestStatusLog/RequestStatusLog";
import { ClientInfoBlock } from "@components/ClientInfoBlock/ClientINfoBlock";
import { ClientDocCheckBlock } from "@components/ClientDocCheckBlock/ClientDocCheckBlock";
import { ButtonsBlock } from "@components/ButtonsBlock/ButtonsBlock";
import { DocPhotoCarousel } from "@components/DocPhotoCarousel/DocPhotoCarousel";

import useCheckRequestFacade from "./useCheckRequestFacade";

import "./CheckRequest.scss";

export const CheckRequest: React.FC = function (): JSX.Element {
  const [isShowingPhoto, setIsShowingPhoto] = useState(false);

  const getShowPhotoSetter = (isShow: boolean) => () =>
    setIsShowingPhoto(isShow);

  const { id } = useParams();
  const [navigateValue, setNavigateValue] = useState("");

  const [isCommentFilled, setIsCommentFilled] = useState(false);

  const checkIsCommentFilled = (value: string): void => {
    if (value) {
      setIsCommentFilled(true);
      setNavigateValue("");
    } else setIsCommentFilled(false);
  };

  const [
    onHaveErrorBtnClick,
    onSucessfulCompletedBtnClick,
    onDeleteOrderBtnClick,
  ] = useCheckRequestFacade();

  return (
    <div className="check-request">
      <RequestTitle type="order" />
      <LogoutPanel />
      <RequestStatusBlock status="pending" />
      <SupervizerFormBlock checkIsCommentEmpty={checkIsCommentFilled} />
      <UserInfoBlock />
      <RequestStatusLog />
      <div className="form">
        <p className="form-title">Новый клиент</p>
        <ClientInfoBlock />
        <ClientDocCheckBlock showPhoto={getShowPhotoSetter(true)} />
        <ButtonsBlock
          config={[
            {
              label: "Успешно проверено",
              onClick: onSucessfulCompletedBtnClick,
              navigateTo: "../../main/task",
            },
            {
              label: "Отправить на доработку",
              disabled: !isCommentFilled,
              styleType: "line",
              onClick: () => onHaveErrorBtnClick(isCommentFilled),
              navigateTo: "../../main/task",
            },
            {
              label: "Редактировать",
              navigateTo: `../../request-edit/${id}`,
            },
            // {
            //   label: "Удалить",
            //   disabled: isAllowDelete,
            //   onClick: onDeleteOrderBtnClick,
            //   styleType: "line",
            //   navigateTo: "../main/task",
            // },
            {
              label: "Вернуться",
              styleType: "line",
              navigateTo: "../../main/task",
            },
          ]}
        />
      </div>
      {isShowingPhoto && (
        <DocPhotoCarousel closeIt={getShowPhotoSetter(false)} />
      )}
    </div>
  );
};
