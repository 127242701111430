import React from "react";

import { RequestStatusLogProps } from "./RequestStatusLogProps";

import useRequestStatusLogFacade from "./useRequestStatusLogFacade";

import "./RequestStatusLog.scss";

export const RequestStatusLog: React.FC = function (): JSX.Element {
  const [statuses] = useRequestStatusLogFacade();
  return (
    <div className="request-status-log">
      {statuses.map(({ status, statusupdate }) => (
        <div className="log-item" key={`${status}_${statusupdate}`}>
          <span>{status}</span>
          <span>{statusupdate}</span>
        </div>
      ))}
    </div>
  );
};
