import { OrderStatus } from "@appTypes/Enums";
import { useAppSelector } from "@hooks/redux";
import convertOrderStatus from "@utils/convertOrderStatus";

export default function useRequestStatusLogFacade(): [RequestStatus[]] {
  const statuses: RequestStatus[] = useAppSelector(
    (state) => state.orders.chosenOrder?.statuses ?? []
  )?.map(({ status, statusupdate }) => ({
    status: convertOrderStatus(status as OrderStatus),
    statusupdate: statusupdate.split("T")[0].split("-").reverse().join("."),
  }));

  return [statuses];
}
