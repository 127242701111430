import React from "react";
import McbTextArea from "mcb-uikit/Textarea/index";

import useSupervizerFormBlockFacade from "./useSupervizerFormBlockFacade";

import "./SupervizerFormBlock.scss";

export const SupervizerFormBlock: React.FC<any> = function ({
  checkIsCommentEmpty,
}: any): JSX.Element {
  const [status, onTextareaChange] = useSupervizerFormBlockFacade();
  checkIsCommentEmpty(status);
  return (
    <div className="supervizer-form-block">
      <p>Комментарий супервайзера</p>
      <McbTextArea value={status} onChange={onTextareaChange} />
    </div>
  );
};
