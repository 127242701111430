/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  user: IUser;
  error: string | undefined;
  token: string;
}

const initialState: AuthState = {
  user: {} as IUser,
  error: "",
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authSuccess(state, action: PayloadAction<IUser>) {
      state.error = "";
      state.user = action.payload;
    },
    authError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    keycloakToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    }
  },
});

export const { authSuccess, authError, keycloakToken } = authSlice.actions;

export default authSlice.reducer;
