import { useState } from "react";
import { useAppSelector } from "@hooks/redux";

export default function useClientDocBlockFacade(): [
  Record<DocumentTypes, Record<"title" | "comment", string>>,
  boolean
] {
  const attache = useAppSelector((state) => state.orders.chosenOrder?.attache);
  const isResident = useAppSelector(
    (state) => state.orders.chosenOrder?.resident as boolean
  );

  const getImageTitle = (key: DocumentTypes): string => {
    const data = attache?.find((item) => item.documentType === key)?.fileAttach;
    if (!data) return "";
    return new Array(data.length)
      .fill(null)
      .map((_, index: number) => `Изображение_${index + 1}`)
      .join(", ");
  };

  const getComment = (type: DocumentTypes): string =>
    attache?.find((doc) => doc.documentType === type)?.comment ?? "";

  const getDocDataItem = (
    key: DocumentTypes | AnotherDocumentKey
  ): Record<"title" | "comment", string> => ({
    title: getImageTitle(key),
    comment: getComment(key),
  });

  const getDocData = (): Record<
    DocumentTypes,
    Record<"title" | "comment", string>
  > => {
    const preDocData = {
      passport_main: getDocDataItem("passport_main"),
      dbo_statement: getDocDataItem("dbo_statement"),
    };

    const residentDoc = {
      passport_previous: getDocDataItem("passport_previous"),
      passport_registration: getDocDataItem("passport_registration"),
    };

    const notResidentDoc: Record<
      NotResidentDocuments,
      Record<"title" | "comment", string>
    > = {
      migration_card: getDocDataItem("migration_card"),
      visa: getDocDataItem("visa"),
      temporary: getDocDataItem("temporary"),
      residence_permit: getDocDataItem("residence_permit"),
    };

    const anotherDocKey = attache?.reduce((acc, { documentType }) => {
      if (documentType.includes("another_document")) {
        acc[documentType as AnotherDocumentKey] = getDocDataItem(documentType);
      }
      return acc;
    }, {} as Record<AnotherDocumentKey, Record<"title" | "comment", string>>);

    return {
      ...preDocData,
      ...residentDoc,
      ...notResidentDoc,
      ...anotherDocKey,
    };
  };

  return [getDocData(), isResident];
}
