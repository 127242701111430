import React, { useEffect, useState } from "react";
import { Row, Form, Col } from "react-bootstrap";
import RectButton from "mcb-uikit/RectButton/index";
import Input from "mcb-uikit/DefaultInput/index";

import { useAppSelector } from "@hooks/redux";
import { authorizeAPI } from "@api/api";
import "./ConfirmationForm.scss";

interface ConfirmationFormProps {
  isBlockButtons: boolean;
  setIsAuth(value: boolean): void;
}

export const ConfirmationForm: React.FC<ConfirmationFormProps> = function ({
  isBlockButtons,
  setIsAuth,
}): JSX.Element {
  const [SMSCode, setSMSCode] = useState<number>();
  const [isSMSInputError, setIsSMSInputError] = useState(false);
  const [SMSErrorText, setSMSErrorText] = useState("");
  const [timerCount, setTimerCount] = useState(0);
  const [isDisableButton, setIsDisableButton] = useState(false);

  const login = useAppSelector((state) => state.user.login);

  const getOnChange = (event: React.ChangeEvent): void => {
    const { value } = event.target as HTMLInputElement;
    setSMSCode(Number(value));
    setIsSMSInputError(false);
  };

  const requestSmsCode = (): void => {
    authorizeAPI.getSMSCode();
    setTimerCount(40);
  };

  const sendSMSCode = (): void => {
    if (!SMSCode) {
      setIsSMSInputError(true);
      setSMSErrorText("Введите код");
    } else {
      setIsSMSInputError(false);
      authorizeAPI.sendSMSCode(SMSCode, login).then((res) => {
        if (res?.data?.code === "ok") setIsAuth(false);
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    timerCount > 0 && setTimeout(() => setTimerCount(timerCount - 1), 1000);
    // eslint-disable-next-line no-unused-expressions
    timerCount > 0 ? setIsDisableButton(true) : setIsDisableButton(false);
  }, [timerCount]);

  useEffect(() => {
    setTimerCount(40);
    authorizeAPI.getSMSCode();
  }, []);

  return (
    <div className="confirmation_form">
      <p className="confirmation_title">Подтверждение</p>
      <span className="confirmation_subtitle">
        Отправлено SMS-сообщение с кодом подтверждения на номер телефона.
      </span>
      <Form onSubmit={(e) => e.preventDefault()} className="auth_form">
        <Form.Group className="auth_login" controlId="authLogin">
          <Row>
            <Form.Label className="confirmation_subtitle" column sm={5}>
              Код из SMS
            </Form.Label>
            <Input
              height={56}
              hasError={isSMSInputError}
              errorText={SMSErrorText}
              onChange={getOnChange}
              placeholder="Введите код"
            />
          </Row>
        </Form.Group>
        <div className="auth_button">
          <RectButton
            disabled={isBlockButtons}
            styleType="red"
            onClick={sendSMSCode}
          >
            Подтвердить
          </RectButton>
          <RectButton
            disabled={isDisableButton || isBlockButtons}
            styleType="red"
            onClick={requestSmsCode}
          >
            Запросить код повторно
          </RectButton>
        </div>

        <span className="title">
          {isDisableButton &&
            !isBlockButtons &&
            `Запросить код повторно можно через ${timerCount} секунд`}
          {isBlockButtons && `Повторите запрос позже`}
        </span>
      </Form>
    </div>
  );
};
