import { useState } from "react";
import { useAppSelector } from "@hooks/redux";
import { applicationAPI } from "@api/api";
import { dispatch } from "@store/index";
import { setPaginationProperties } from "@store/reducers/order/OrderSlice";

export default function useMainFilterPanelFacade(): [
  OrderPaginationProperties["page"],
  (type: "increase" | "decrease") => () => void,
  () => void
] {
  const dispatchAction = (value: Partial<OrderPaginationProperties>): void => {
    dispatch(setPaginationProperties(value));
  };
  const paginationProperties = useAppSelector(
    (state) => state.orders.orderPaginationProperties
  );

  const getPageSetter = (type: "increase" | "decrease") => (): void => {
    const { page } = paginationProperties;
    const newPage = page + (type === "increase" ? 1 : -1);
    dispatchAction({ page: newPage });
  };

  const setAscending = (): void => {
    dispatchAction({ ascending: !paginationProperties.ascending });
  };

  return [paginationProperties.page, getPageSetter, setAscending];
}
