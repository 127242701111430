/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
import {
  createSlice,
  PayloadAction,
  current,
  original,
} from "@reduxjs/toolkit";
import { applicationAPI } from "@api/api";
import { OrderStatus } from "@appTypes/Enums";

interface OrderState {
  orders: IRequest[];
  chosenOrder: IRequest | undefined;
  newOrder: IRequest | undefined;
  orderPaginationProperties: OrderPaginationProperties;
}

const newOrderInitState: IRequest = {
  id: undefined as unknown as number,
  fio: "",
  birthdate: "",
  organization: {
    name: "",
    inn: "",
  },
  fioAgent: "",
  dateDepart: "",
  resident: true,
  comments: "",
  attache: [],
  statuses: [],
  status: OrderStatus.draft,
};

const paginationInitState: OrderPaginationProperties = {
  page: 1,
  sortby: "order.id",
  ascending: false,
};

const initialState: OrderState = {
  orders: [],
  chosenOrder: undefined,
  newOrder: { ...newOrderInitState },
  orderPaginationProperties: { ...paginationInitState },
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders(state, action: PayloadAction<IRequest[]>) {
      state.orders = action.payload;
    },
    setChosenOrder(state, action: PayloadAction<IRequest>) {
      state.chosenOrder = {
        ...action.payload,
        birthdate:
          action.payload?.birthdate
            ?.split(" ")[0]
            .split(".")
            .reverse()
            .join("-") ?? null,
        dateDepart:
          action.payload?.dateDepart
            ?.split(" ")[0]
            .split(".")
            .reverse()
            .join("-") ?? null,
      };

      // state.chosenOrder = action.payload;
    },
    setNewOrder(state, action: PayloadAction<Partial<IRequest>>) {
      if (!state.newOrder) return;
      state.newOrder.id = action.payload.id as number;
    },
    updateNewOrder(state, action: PayloadAction<Partial<IRequest>>) {
      if (!state.newOrder) state.newOrder = action.payload as IRequest;
      if (!action.payload) state.newOrder = undefined;
      state.newOrder = { ...state.newOrder, ...(action.payload as IRequest) };
      applicationAPI.update(state.newOrder);
    },
    updateChosenOrder(state, action: PayloadAction<Partial<IRequest>>) {
      if (!state.chosenOrder) state.chosenOrder = action.payload as IRequest;
      state.chosenOrder = {
        ...state.chosenOrder,
        ...(action.payload as IRequest),
      };
      applicationAPI.update(state.chosenOrder);
    },
    updateNewOrderAttach(
      state,
      action: PayloadAction<IRequest["attache"][number]>
    ) {
      if (!state.newOrder) return;
      const index = state.newOrder.attache.findIndex(
        (file: IAttach) => file.documentType === action.payload.documentType
      );
      if (index !== -1 && index !== undefined) {
        const oldAttach = state.newOrder.attache[index];
        state.newOrder.attache[index] = { ...oldAttach, ...action.payload };
      } else {
        state.newOrder.attache = [
          ...state.newOrder.attache,
          { ...action.payload, comment: "" },
        ];
      }
      if (action.payload.fileAttach.length !== 0) {
        applicationAPI.update({ ...current(state.newOrder) });
      }
    },
    updateChosenOrderAttach(
      state,
      action: PayloadAction<IRequest["attache"][number]>
    ) {
      console.log(current(state?.chosenOrder?.attache));

      if (!state.chosenOrder) return;
      if (!state.chosenOrder.attache) {
        state.chosenOrder["attache"] = [];
        state.chosenOrder.attache = [action.payload];
        applicationAPI.update(current(state.chosenOrder) as IEditableRequest);
        console.log("update attache");
        return;
      }
      const index = state.chosenOrder.attache.findIndex(
        (file: IAttach) => file.documentType === action.payload.documentType
      );
      if (index !== undefined && index !== -1) {
        const oldAttach = state.chosenOrder.attache[index];
        state.chosenOrder.attache[index] = { ...oldAttach, ...action.payload };
      } else {
        state.chosenOrder.attache = [
          ...state.chosenOrder.attache,
          action.payload,
        ];
      }

      if (action.payload.fileAttach.length !== 0) {
        applicationAPI.update(current(state.chosenOrder) as IEditableRequest);
      }
    },
    updateChosenOrderComment(
      state,
      action: PayloadAction<IRequest["comments"]>
    ) {
      if (!state.chosenOrder) return;
      state.chosenOrder.comments = action.payload;

      applicationAPI.update(state.chosenOrder);
    },
    updateChosenOrderDocComment(
      state,
      action: PayloadAction<Pick<IAttach, "documentType" | "comment">>
    ) {
      if (!state.chosenOrder) return;
      const index = state.chosenOrder.attache?.findIndex(
        (item) => item.documentType === action.payload.documentType
      );
      if (index === -1 || index === undefined) {
        state.chosenOrder.attache = state.chosenOrder.attache
          ? [
              ...state.chosenOrder.attache,
              {
                fileAttach: [],
                ...action.payload,
              },
            ]
          : [
              {
                fileAttach: [],
                ...action.payload,
              },
            ];
      } else {
        state.chosenOrder.attache[index] = {
          ...state.chosenOrder.attache[index],
          comment: action.payload.comment,
        };
      }
      applicationAPI.update(state.chosenOrder);
    },
    deleteNewOrderDoc(state, action: PayloadAction<DocumentTypes>) {
      if (!state.newOrder?.attache) return;
      state.newOrder.attache = state.newOrder.attache.filter(
        (item) => item.documentType !== action.payload
      );
      applicationAPI.update(state.newOrder);
    },
    deleteChosenOrderDoc(state, action: PayloadAction<DocumentTypes>) {
      if (!state.chosenOrder?.attache) return;
      state.chosenOrder.attache = state.chosenOrder.attache.filter(
        (item) => item.documentType !== action.payload
      );
      applicationAPI.update(state.chosenOrder);
    },
    resetAllOrders(state, action: PayloadAction<undefined>) {
      const currentPagProps = { ...state.orderPaginationProperties };
      state = { ...initialState, ...currentPagProps };
    },
    resetAllOrdersWithPagination(state, action: PayloadAction) {
      state = { ...initialState };
    },
    setPaginationProperties(
      state,
      action: PayloadAction<Partial<OrderPaginationProperties>>
    ) {
      state.orderPaginationProperties = {
        ...state.orderPaginationProperties,
        ...action.payload,
      };
      applicationAPI.getAll(state.orderPaginationProperties);
    },
  },
});

export const {
  setOrders,
  setChosenOrder,
  setNewOrder,
  updateNewOrder,
  updateChosenOrder,
  updateChosenOrderAttach,
  updateNewOrderAttach,
  updateChosenOrderComment,
  updateChosenOrderDocComment,
  deleteChosenOrderDoc,
  deleteNewOrderDoc,
  resetAllOrders,
  setPaginationProperties,
  resetAllOrdersWithPagination,
} = orderSlice.actions;
export default orderSlice.reducer;
