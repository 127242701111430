import React from "react";
import { Container } from "react-bootstrap";

import { MainFilterPanel } from "@components/MainFilterPanel/MainFilterPanel";
import { Table } from "@components/Table/Table";

import { applicationAPI, exportReportAPI } from "@api/api";

import { useAppSelector } from "@hooks/redux";
import { MainSortPanel } from "@components/MainSortPanel/MainSortPanel";
import useMyRequestFacade from "./useMyRequestFacade";

import { tableHeaders } from "./TableConfig/TableHeader";
import { TableData } from "./TableConfig/TableData";

import "./MyRequests.scss";

export const MyRequests: React.FC = function (): JSX.Element {
  const [tableData] = useMyRequestFacade();
  const role = useAppSelector((state) => state.user.role);
  const isAllowCreateOrder =
    role.includes("dsa_director") ||
    role.includes("dsa_supervisor") ||
    role.includes("dsa_administrator");
  const onCreateRequestButtonClick = (): void => {
    applicationAPI.create();
  };
  return (
    <Container className="my_request">
      <MainFilterPanel
        type="orders"
        buttonsConfig={[
          {
            label: "Создать новую заявку",
            navigateTo: "/request-new",
            disabled: isAllowCreateOrder,
            onClick: onCreateRequestButtonClick,
          },
          {
            label: "Выгрузить отчет",
            onClick: () => exportReportAPI.exportReport(),
          },
        ]}
      />
      <MainSortPanel componentOption="orders" />
      <Table headers={tableHeaders} data={tableData} dataType="Order" />
    </Container>
  );
};
