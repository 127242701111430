import React from "react";
import Button from "mcb-uikit/RoundButton/index";

import { ModalBackground } from "@components/ModalBackground/ModalBackground";
import CheckIcon from "@components/Icons/Check";
import Images from "@components/Icons/Images";

import CameraComponentProps from "./CameraComponentProps";

import PhotoController from "./PhotoController/PhotoController";

import useCameraComponentFacade from "./useCameraComponentFacade";

import "./CameraComponent.scss";

export const CameraComponent: React.FC<CameraComponentProps> = function ({
  getCloseIt,
}): JSX.Element {
  const [
    videoRef,
    canvasRef,
    photos,
    isHaveVideo,
    isShowPhotos,
    confirmedPhotos,
    setterPhotos,
    setIsHaveVideo,
    setIsShowPhotos,
    setConfirmedPhotos,
    confirmAllPhotos,
    takePicture,
  ] = useCameraComponentFacade();

  return (
    <ModalBackground subClass="camera">
      {isShowPhotos ? (
        <PhotoController
          photos={photos}
          confirmedPhotos={confirmedPhotos}
          closeIt={() => setIsShowPhotos(false)}
          setPhotos={setterPhotos}
          setConfirmedPhotos={setConfirmedPhotos}
        />
      ) : (
        <div className="camera-component">
          <video
            autoPlay
            loop
            muted
            ref={videoRef}
            onPlay={() => setIsHaveVideo(true)}
          />
          <canvas ref={canvasRef} hidden />
          {isHaveVideo && (
            <>
              <Button
                sign="close"
                onClick={getCloseIt(confirmedPhotos)}
                rootClassName="app-btn-close"
              />
              <div className="panel">
                <div className="camera-controls-block">
                  <button
                    type="button"
                    className="btn-images"
                    disabled={photos.length < 1}
                    onClick={() => setIsShowPhotos(true)}
                  >
                    <Images disabled={photos.length < 1} />
                  </button>
                  <button
                    type="button"
                    onClick={() => takePicture()}
                    className="btn-take-photo"
                  >
                    <div />
                  </button>
                  <button
                    type="button"
                    className="btn-confirm-photo"
                    onClick={confirmAllPhotos}
                    disabled={photos.length < 1}
                  >
                    <CheckIcon disabled={photos.length < 1} />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </ModalBackground>
  );
};
