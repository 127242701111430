import React from "react";

import { LogoutPanel } from "@components/LogoutPanel/LogoutPanel";
import { ButtonsBlock } from "@components/ButtonsBlock/ButtonsBlock";

import useWatchCompanyFacade from "./useWatchCompanyFacade";

import "./WatchCompany.scss";

const WatchCompany: React.FC = function (): JSX.Element {
  const [id, name, inn, adress, buttonsConfig] = useWatchCompanyFacade();
  return (
    <div className="watch-company">
      <LogoutPanel />
      <p className="title">Организация № {id}</p>
      <div className="company-info">
        <div className="doc-item">
          <p>Название</p>
          <p>{name}</p>
        </div>
        <div className="doc-item">
          <p>ИНН</p>
          <p>{inn}</p>
        </div>
        <div className="doc-item">
          <p>Адресс</p>
          <p>{adress}</p>
        </div>
        <ButtonsBlock config={buttonsConfig} />
      </div>
    </div>
  );
};

export default WatchCompany;
