import { useAppSelector } from "@hooks/redux";
import { useParams } from "react-router-dom";
import { OrderStatus } from "@appTypes/Enums";

export default function useRequestStatusBlockFacade(): [OrderStatus | ""] {
  const id = useParams().id ?? undefined;
  const isNewOrder = !id;
  const chosenOrder = useAppSelector((state) => state.orders.chosenOrder);
  const status = isNewOrder
    ? OrderStatus.draft
    : chosenOrder?.statuses.at(-1)?.status ?? "";

  return [status];
}
