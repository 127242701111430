import React, { useEffect } from "react";
import { Route, Routes, Link, useMatch, useNavigate } from "react-router-dom";
import { Container, Row, Navbar, Nav } from "react-bootstrap";
import { applicationAPI, organizationAPI, authorizeAPI } from "@api/api";
import { dispatch } from "@store/index";
import { resetAllOrders } from "@store/reducers/order/OrderSlice";
import { resetAllOrganizations } from "@store/reducers/organization/Organizations";

import { LogoutPanel } from "@components/LogoutPanel/LogoutPanel";
import { MyRequests } from "@components/MyRequests/MyRequests";
import { Companies } from "@components/Companies/Companies";
import $api from "../../api/index";

import "./Main.scss";

export const Main: React.FC = function (): JSX.Element {
  const mathcPath = useMatch("/main/task");
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetAllOrders());
    dispatch(resetAllOrganizations());
    applicationAPI.getAll();
    organizationAPI.getAll();
  }, []);
  return (
    <Container className="main" fluid>
      <div className="main_navbar_wrapper">
        <Navbar className="main_navbar">
          <Container className="navs_container" fluid>
            <div className="links_wrapper">
              <div className="brand_wrapper">
                <Navbar.Brand>МКБ</Navbar.Brand>
              </div>
              <Nav className="me-auto">
                <Container className="navlink-container">
                  <Row>
                    <Link
                      to="task"
                      className={`nav-link${mathcPath ? " active" : ""}`}
                    >
                      Мои заявки
                    </Link>
                  </Row>
                  <Row>
                    <Link
                      to="organization"
                      className={`nav-link${!mathcPath ? " active" : ""}`}
                    >
                      Организации
                    </Link>
                  </Row>
                </Container>
              </Nav>
            </div>
          </Container>
        </Navbar>
      </div>
      <LogoutPanel />
      <div className="component">
        <Routes>
          <Route path="task" element={<MyRequests />} />
          <Route path="organization" element={<Companies />} />
        </Routes>
      </div>
    </Container>
  );
};
