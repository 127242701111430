import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Main } from "@pages/Main/Main";
import { NewCompany } from "@pages/NewCompany/NewCompany";
import { NewRequest } from "@pages/NewRequest/NewRequest";
import { RequestStatus } from "@pages/RequestStatus/RequestStatus";
import { CheckRequest } from "@pages/CheckRequest/CheckRequest";
import WatchCompany from "@pages/WatchCompany/WatchCompany";
import { ConfirmationForm } from "@components/ConfirmationFormComponent/ConfirmationForm";
import { Modal } from "@components/Modal/Modal";
import $api from "./api";

import useAppFacade from "./useAppFacade";

export const App: React.FC = function (): JSX.Element {
  const [isHaveAuthorisationError, setIsHaveAuthorisationError] =
    useState(false);
  const [isHaveAnyError, setIsHaveAnyError] = useState(false);
  const [errorCode, setErrorCode] = useState() as any;
  const [errorDescription, setErrorDescription] = useState() as any;
  const [isAuthorised, keycloakError] = useAppFacade();
  const [isBlockConfirmationButtons, setIsBlockConfirmationButtons] =
    useState(false);

  if (!isAuthorised) {
    return <div> Login error </div>;
  }

  if (keycloakError) {
    setIsHaveAnyError(true);
    setErrorCode(keycloakError.code);
    setErrorDescription(keycloakError.message);
  }

  $api.interceptors.response.use(undefined, (error) => {
    if (error.response) {
      setIsHaveAnyError(true);
      setErrorCode(error.code);
      setErrorDescription(error.message);
      if (error.response.status === 402 || error.response.status === 403) {
        setIsHaveAuthorisationError(true);
        if (error.response.status === 402) setIsHaveAnyError(false);
      }
      if (error.response.status === 400) {
        setErrorCode("Error 400");
        setErrorDescription("Авторизация не пройдена. Введен неверный код.");
      }
      if (error.response.status === 403) {
        setErrorCode("Error 403");
        setErrorDescription(
          " Авторизация не пройдена. Повторите запрос позже."
        );
        setIsBlockConfirmationButtons(true);
      }
    }
  });

  if (isHaveAuthorisationError)
    return (
      <>
        {isHaveAnyError && (
          <Modal
            title={errorCode}
            description={errorDescription}
            closeModal={() => setIsHaveAnyError(false)}
          />
        )}
        <ConfirmationForm
          isBlockButtons={isBlockConfirmationButtons}
          setIsAuth={setIsHaveAuthorisationError}
        />
        ;
      </>
    );

  return (
    <>
      {isHaveAnyError && (
        <Modal
          title={errorCode}
          description={errorDescription}
          closeModal={() => setIsHaveAnyError(false)}
        />
      )}
      <BrowserRouter>
        <Routes>
          <Route path="/main/*" element={<Main />} />
          <Route path="/new-company" element={<NewCompany />} />
          <Route path="/company-watch/:id" element={<WatchCompany />} />
          <Route path="/edit-company/:id" element={<NewCompany />} />
          <Route path="/request-new" element={<NewRequest type="new" />} />
          <Route
            path="/request-edit/:id"
            element={<NewRequest type="edit" />}
          />
          <Route path="/request-status/:id" element={<RequestStatus />} />
          <Route path="/request-check/:id" element={<CheckRequest />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
