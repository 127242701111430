import React from "react";
import Selector from "mcb-uikit/MultiSelectReact/index";
import "./MainSortPanel.scss";
import useMainSortPanelFacade from "./useMainSortPanelFacade";

export const MainSortPanel: React.FC<any> = function (props): JSX.Element {
  const [setSortKey, setSortType, choosenKey, choosenType, keyLabels] =
    useMainSortPanelFacade(props.componentOption);
  return (
    <div className="sort_container">
      <Selector
        value={{ label: choosenKey as string }}
        className="select_field"
        placeholder="Сортировать по"
        name="clientName"
        options={keyLabels}
        isMulti={false}
        onChange={setSortKey}
      />
      <Selector
        value={{ label: choosenType }}
        className="select_type"
        options={[{ label: "По возрастанию" }, { label: "По убыванию" }]}
        isMulti={false}
        placeholder="Тип сортировки"
        onChange={setSortType}
      />
    </div>
  );
};
