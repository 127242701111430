import { useAppSelector } from "@hooks/redux";
import convertOrderStatus from "@utils/convertOrderStatus";
import findLatestUpdateStatus from "@utils/findLatestUpdateStatus";

export default function useMyRequestFacade(): any[] {
  const orders = useAppSelector((storeValue) => storeValue.orders.orders);
  const tableData = orders.map((item) => ({
    id: item.id,
    name: item.fio,
    birthDate: item.birthdate,
    company: item.name,
    iin: item.inn,
    agentName: item.fioAgent,
    status: convertOrderStatus(findLatestUpdateStatus(item)),
    arrivedDate: item.dateDepart,
    lastChangeDate: item.statuses[item.statuses.length - 1]?.statusupdate
      .split("T")[0]
      .split("-")
      .reverse()
      .join("."),
  }));

  return [tableData];
}
