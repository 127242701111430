const findLatestUpdateStatus = (item: any): any => {
  let max = item?.statuses?.[0];
  item?.statuses.forEach((el: any) => {
    if (
      new Date(el.statusupdate).getTime() > new Date(max.statusupdate).getTime()
    ) {
      max = el;
    }
  });
  if (max?.status) return max?.status;
};

export default findLatestUpdateStatus;
