import { useParams } from "react-router-dom";
import { useAppSelector } from "@hooks/redux";
import { RequestTitleProps } from "./RequestTitleProps";

export default function useRequestTitleFacade(
  type: RequestTitleProps["type"]
): [string] {
  const role = "";
  const superViserTitle = useAppSelector(
    (state) => state.orders.chosenOrder?.id.toString() ?? ""
  );

  const agentTitle = useParams().id
    ? "Редактирование организации"
    : "Новая организация";

  const title = type === "order" ? `Заявка №${superViserTitle}` : agentTitle;

  return [title];
}
