import React from "react";
import McbButton from "mcb-uikit/RectButton/index";

import { ClientDocCheckBlockProps } from "./ClientDocCheckBlockProps";

import useClientDocCheckBlockFacade from "./useClientDocCheckBlockFacade";

import { DocCheckBlock } from "./DocCheckBlock/DocCheckBlock";

import "./ClientDocCheckBlock.scss";

export const ClientDocCheckBlock: React.FC<ClientDocCheckBlockProps> =
  function ({ showPhoto }): JSX.Element {
    const [fieldsType] = useClientDocCheckBlockFacade();

    return (
      <div className="client-doc-check">
        <div className="header">
          <span>Документы</span>
          <McbButton onClick={showPhoto} styleType="line">
            Посмотреть все
          </McbButton>
        </div>
        <div>
          {fieldsType.map((item) => (
            <DocCheckBlock type={item} />
          ))}
        </div>
      </div>
    );
  };
