import { TableProps } from "@components/Table/TableProps";

export const tableHeaders: TableProps["headers"] = [
  {
    label: "№ п/п",
    accessor: "",
  },
  {
    label: "Название организации",
    accessor: "",
  },
  {
    label: "ИНН организации",
    accessor: "",
  },
  {
    label: "Адрес Организации",
    accessor: "",
  },
];
