import React from "react";
import { Table as BootstrapTable } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Modal } from "@components/Modal/Modal";
import { useAppSelector } from "@hooks/redux";
import { UserRoles } from "@appTypes/Enums";
import { applicationAPI, organizationAPI } from "@api/api";
import { ContextMenuModal } from "./ContextMenuModal";
import "./ContextMenuModal.scss";

import { TableProps } from "./TableProps";

export const Table: React.FC<TableProps> = function (props): JSX.Element {
  const navigate = useNavigate();
  const [isOpenContextMenu, setIsOpenContextMenu] =
    React.useState<boolean>(false);
  const [contextModalX, setContextModalX] = React.useState<number>();
  const [contextModalY, setContextModalY] = React.useState<number>();
  const [orderId, setOrderId] = React.useState<number>();
  const [modal, setModal] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");
  const openModal = (name: string): void => {
    setModal(true);
    setName(name);
  };
  const closeModal = (): void => {
    setModal(false);
    setName("");
  };

  const role = useAppSelector((state) => state.user.role);
  const getOnTrClick =
    (id: number) =>
    (event: React.MouseEvent): void => {
      const { dataType } = props;
      setOrderId(id);
      if (role === UserRoles.admin && dataType === "Order") {
        setIsOpenContextMenu(!isOpenContextMenu);
        setContextModalX(event.clientX);
        setContextModalY(event.clientY);
      } else if (role === UserRoles.supervizer && dataType === "Order") {
        navigate(`/request-check/${id}`);
      } else if (dataType === "Order") {
        navigate(`/request-status/${id}`);
      } else {
        organizationAPI.get(id);
        navigate(`/company-watch/${id}`);
      }
    };

  const EditOrder = (): void => {
    applicationAPI.get(orderId as number);
    navigate(`/request-edit/${orderId}`);
  };

  const SubmitOrderForReview = (): void => {
    applicationAPI.get(orderId as number);
    navigate(`/request-check/${orderId}`);
  };

  return (
    <BootstrapTable className="app-table" bordered>
      <thead className="table_head">
        <tr>
          {props.headers.map(({ label, accessor }) => (
            <th key={`${label}_${accessor}`}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody style={{ position: "relative" }}>
        {props.data.map((item) => (
          <tr
            // onClick={() => openModal(`${index}`)}
            onClick={getOnTrClick(item.id as number)}
            key={Object.values(item).join("")}
          >
            {Object.values(item).map((itemValue, index) => {
              if (index >= props.headers.length) return null;
              return <td>{itemValue}</td>;
            })}
          </tr>
        ))}
        {isOpenContextMenu && (
          <ContextMenuModal>
            <div
              className="menu_block"
              style={{
                position: "fixed",
                zIndex: 5,
                top: contextModalY,
                left: contextModalX,
                backgroundColor: "white",
                border: "1px solid red",
                borderRadius: "5px",
                padding: "5px",
              }}
            >
              <div className="menuItem" onClick={EditOrder} role="contentinfo">
                Редактировать
              </div>
              <div
                className="menuItem"
                onClick={SubmitOrderForReview}
                role="contentinfo"
              >
                Проверить
              </div>
            </div>
          </ContextMenuModal>
        )}
      </tbody>
      {modal ? (
        <Modal
          closeModal={closeModal}
          title="Уведомление"
          description="Заявка в работе"
        />
      ) : null}
    </BootstrapTable>
  );
};
