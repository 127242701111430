import axios, { AxiosRequestConfig } from "axios";
import { getState } from "@store/index";

export const API_URL = process.env.BACKEND_URL;

const $api = axios.create({
  // withCredentials: true,
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

$api.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  newConfig.headers = {
    Authorization: `Bearer ${getState().auth.token}`,
  };
  return newConfig;
});

export default $api;
