import { useAppSelector } from "@hooks/redux";

export default function useClientDocCheckBlockFacade(): [
  IAttach["documentType"][]
] {
  const attacheTypes =
    useAppSelector(({ orders }) => orders.chosenOrder?.attache)?.reduce(
      (acc, { documentType }) => {
        acc.push(documentType);
        return acc;
      },
      [] as DocumentTypes[]
    ) ?? [];

  // const fieldsTypes: DocumentTypes[] = [
  //   "passport_main",
  //   "passport_registration",
  //   "passport_previous",
  //   "dbo_statement",
  //   // "clients_photo",
  //   "another_document",
  // ];

  return [attacheTypes];
}
