import React, { useState, useEffect } from "react";
import { FormLabel } from "react-bootstrap";

import { CameraComponent } from "@components/CameraComponent/CameraComponent";
import { InputFileProps } from "./InputFileProps";

import "./InputFile.scss";

export const InputFile: React.FC<InputFileProps> = function ({
  id,
  placeholder,
  outSideFilename,
  onChange,
  onDeleteButtonClick,
  isAddButtonDisablled,
}): JSX.Element {
  const [fileName, setFileName] = useState(outSideFilename ?? "");
  const [isCameraActive, setIsCameraActive] = useState(false);

  useEffect(() => {
    setFileName(outSideFilename as string);
  }, [outSideFilename]);

  const onClick = (event: React.MouseEvent): void => {
    if (fileName) {
      event.preventDefault();
    } else {
      setIsCameraActive(true);
    }
  };

  const onPseudoButtonClick = (event: React.MouseEvent): void => {
    if (fileName) {
      event.preventDefault();
      setFileName("");
      onDeleteButtonClick();
    }
  };
  return (
    <>
      <FormLabel className="input-file" onClick={onClick}>
        <span className={fileName ? "file-label" : "placeholder-label"}>
          {fileName || placeholder}
        </span>
        {!isAddButtonDisablled && (
          <div
            className="pseudo-button"
            onClick={onPseudoButtonClick}
            role="form"
          >
            {fileName ? "Удалить" : "Добавить"}
          </div>
        )}
      </FormLabel>
      {isCameraActive && (
        <CameraComponent
          getCloseIt={(data: string[]) => () => {
            onChange(data);
            setIsCameraActive(false);
          }}
        />
      )}
    </>
  );
};
