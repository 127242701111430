import { useEffect } from "react";
import { useAppSelector } from "@hooks/redux";

export default function useDocPhotoCarouselFacade(): [string[]] {
  const photos = useAppSelector(
    (state) =>
      state.orders.chosenOrder?.attache?.reduce((acc, curr) => {
        return [...acc, ...curr.fileAttach];
      }, [] as string[]) ?? []
  );

  return [photos];
}
