import React from "react";

import { RequestTitleProps } from "./RequestTitleProps";

import useRequestTitleFacade from "./useRequestTitleFacade";

import "./RequestTitle.scss";

export const RequestTitle: React.FC<RequestTitleProps> = function ({
  type,
}): JSX.Element {
  const [title] = useRequestTitleFacade(type);
  return <span className="req-title">{title}</span>;
};
