import React from "react";
import RectButton from "mcb-uikit/RectButton/index";
import { ModalBackground } from "@components/ModalBackground/ModalBackground";
import { ModalProps } from "react-bootstrap";

import "./Modal.scss";

export const Modal: React.FC<ModalProps> = function (props): JSX.Element {
  return (
    <ModalBackground onClick={props.closeModal}>
      <div className="popup">
        <div className="popup-header">
          <h5 className="popup-title">{props.title}</h5>
        </div>
        <div className="popup-body">
          <p>{props.description}</p>
        </div>
        <div className="popup-footer">
          <RectButton onClick={props.closeModal} styleType="line">
            Закрыть
          </RectButton>
        </div>
      </div>
    </ModalBackground>
  );
};
