/* eslint-disable camelcase */
import React from "react";
import { FormGroup, FormLabel } from "react-bootstrap";
import Button from "mcb-uikit/RoundButton/index";

import { InputFile } from "@components/InputFile/InputFile";

import useDocumentFormGroupFacade from "./useDocumentsFormGroupFacade";

import "./DocumentsFormGroup.scss";

export const DocumentsFormGroup: React.FC = function (): JSX.Element {
  const [
    getOnChangeForInput,
    getClassNameWithComment,
    docInfo,
    getImageNames,
    getOnDeleteButtonClick,
    isResident,
    onAddAnotherDocumentButtonClick,
    getIsNotResidentFieldActive,
  ] = useDocumentFormGroupFacade();
  return (
    <FormGroup className={`documents-group-form${getClassNameWithComment()}`}>
      <div className="control-block">
        <FormLabel>Разворот паспорта</FormLabel>
        <InputFile
          onChange={getOnChangeForInput("passport_main")}
          id="passport"
          placeholder="2-3 стр. с фотографией"
          outSideFilename={getImageNames(docInfo.passport_main?.fileLength)}
          onDeleteButtonClick={getOnDeleteButtonClick("passport_main")}
        />
        {docInfo.passport_main?.commnent && (
          <span>Комментарий: {docInfo.passport_main?.commnent}</span>
        )}
      </div>
      {isResident ? (
        <>
          <div className="control-block">
            <FormLabel>Страница регистрации</FormLabel>
            <InputFile
              onChange={getOnChangeForInput("passport_registration")}
              id="place-page"
              placeholder="4-12 стр. паспорта"
              outSideFilename={getImageNames(
                docInfo.passport_registration?.fileLength
              )}
              onDeleteButtonClick={getOnDeleteButtonClick(
                "passport_registration"
              )}
            />
            {docInfo.passport_registration?.commnent && (
              <span>
                Комментарий: {docInfo.passport_registration?.commnent}
              </span>
            )}
          </div>
          <div className="control-block">
            <FormLabel>Предыдущие паспорта</FormLabel>
            <InputFile
              onChange={getOnChangeForInput("passport_previous")}
              id="prev_passport"
              placeholder="18-19 стр. паспорта"
              outSideFilename={getImageNames(
                docInfo.passport_previous?.fileLength
              )}
              onDeleteButtonClick={getOnDeleteButtonClick("passport_previous")}
            />
            {docInfo.passport_previous?.commnent && (
              <span>Комментарий: {docInfo.passport_previous?.commnent}</span>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="control-block">
            <FormLabel>Виза</FormLabel>
            <InputFile
              onChange={getOnChangeForInput("visa")}
              id="visa-photo"
              placeholder=""
              outSideFilename={getImageNames(docInfo.visa?.fileLength)}
              onDeleteButtonClick={getOnDeleteButtonClick("visa")}
              isAddButtonDisablled={!getIsNotResidentFieldActive("visa")}
            />
            {docInfo.visa?.commnent && (
              <span>Комментарий: {docInfo.visa?.commnent}</span>
            )}
          </div>
          <div className="control-block">
            <FormLabel>Миграционная карта</FormLabel>
            <InputFile
              onChange={getOnChangeForInput("migration_card")}
              id="visa-photo"
              placeholder=""
              outSideFilename={getImageNames(
                docInfo.migration_card?.fileLength
              )}
              onDeleteButtonClick={getOnDeleteButtonClick("migration_card")}
              isAddButtonDisablled={
                !getIsNotResidentFieldActive("migration_card")
              }
            />
            {docInfo.migration_card?.commnent && (
              <span>Комментарий: {docInfo.migration_card?.commnent}</span>
            )}
          </div>
          <div className="control-block">
            <FormLabel>Разрешение на временное проживание</FormLabel>
            <InputFile
              onChange={getOnChangeForInput("temporary")}
              id="visa-photo"
              placeholder=""
              outSideFilename={getImageNames(docInfo.temporary?.fileLength)}
              onDeleteButtonClick={getOnDeleteButtonClick("temporary")}
              isAddButtonDisablled={!getIsNotResidentFieldActive("temporary")}
            />
            {docInfo.temporary?.commnent && (
              <span>Комментарий: {docInfo.temporary?.commnent}</span>
            )}
          </div>
          <div className="control-block">
            <FormLabel>Вид на жительство</FormLabel>
            <InputFile
              onChange={getOnChangeForInput("residence_permit")}
              id="visa-photo"
              placeholder=""
              outSideFilename={getImageNames(
                docInfo.residence_permit?.fileLength
              )}
              onDeleteButtonClick={getOnDeleteButtonClick("residence_permit")}
              isAddButtonDisablled={
                !getIsNotResidentFieldActive("residence_permit")
              }
            />
            {docInfo.residence_permit?.commnent && (
              <span>Комментарий: {docInfo.residence_permit?.commnent}</span>
            )}
          </div>
        </>
      )}
      <div className="control-block">
        <FormLabel>Заявление ДБО</FormLabel>
        <InputFile
          onChange={getOnChangeForInput("dbo_statement")}
          id="dbo"
          placeholder=""
          outSideFilename={getImageNames(docInfo.dbo_statement?.fileLength)}
          onDeleteButtonClick={getOnDeleteButtonClick("dbo_statement")}
        />
      </div>
      {docInfo.dbo_statement?.commnent && (
        <span>Комментарий: {docInfo.dbo_statement?.commnent}</span>
      )}
      <div className="control-block">
        <FormLabel>Добавить другой документ</FormLabel>
        <InputFile
          onChange={getOnChangeForInput("another_document1")}
          id="another-doc"
          placeholder=""
          outSideFilename={getImageNames(
            docInfo.another_document1?.fileLength ?? 0
          )}
          onDeleteButtonClick={getOnDeleteButtonClick("another_document1")}
        />
        {docInfo.another_document1?.commnent && (
          <span>Комментарий: {docInfo.another_document1?.commnent}</span>
        )}
      </div>
      {(Object.keys(docInfo) as (keyof typeof docInfo)[])
        .filter(
          (key) =>
            key.includes("another_document") && key !== "another_document1"
        )
        .map((key) => (
          <div className="control-block" key={key}>
            <FormLabel>Добавить другой документ</FormLabel>
            <InputFile
              onChange={getOnChangeForInput(key)}
              id="another-doc"
              placeholder=""
              outSideFilename={getImageNames(docInfo[key]?.fileLength ?? 0)}
              onDeleteButtonClick={getOnDeleteButtonClick(key)}
            />
            {docInfo[key]?.commnent && (
              <span>Комментарий: {docInfo[key].commnent}</span>
            )}
          </div>
        ))}
      <div className="add-another-doc">
        <Button
          ver={1}
          height={32}
          type="button"
          sign="plus"
          onClick={onAddAnotherDocumentButtonClick}
        />
        <span>Добавить поле ввода для другого документа</span>
      </div>
    </FormGroup>
  );
};
