import React from "react";
import { Carousel } from "react-bootstrap";
import Button from "mcb-uikit/RoundButton/index";

import { ModalBackground } from "@components/ModalBackground/ModalBackground";

import { DocPhotoCarouselProps } from "./DocPhotoCarouselProps";

import useDocPhotoCarouselFacade from "./useDocPhotoCarousel";

import "./DocPhotoCarousel.scss";

export const DocPhotoCarousel: React.FC<DocPhotoCarouselProps> = function ({
  closeIt,
}): JSX.Element {
  const [photos] = useDocPhotoCarouselFacade();
  return (
    <ModalBackground onClick={closeIt}>
      <Carousel interval={null} variant="dark">
        {photos.map((photo) => (
          <Carousel.Item>
            <div>
              <img className="d-block w-100" src={photo} alt="First slide" />
              <Button sign="close" onClick={closeIt} />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </ModalBackground>
  );
};
