import React from "react";

import { SupervizerBlockProps } from "./SupervizerBlockProps";

import "./SupervizerBlock.scss";

export const SupervizerBlock: React.FC<SupervizerBlockProps> = function ({
  comment,
  name,
}): JSX.Element {
  return (
    <div className="supervizer-block">
      <p className="title">
        {comment
          ? "Комментарий супервайзера"
          : "ФИО Супервайзера, проверившего заявку"}
      </p>
      <p className="name">{name}</p>
      {comment && <p className="comment">{comment}</p>}
    </div>
  );
};
