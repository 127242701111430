import React, { useState } from "react";
import { useAppSelector } from "@hooks/redux";
import { updateChosenOrderComment } from "@store/reducers/order/OrderSlice";
import { dispatch } from "@store/index";

export default function useSupervizerFormBlockFacade(): [
  string,
  (event: React.ChangeEvent<HTMLTextAreaElement>) => void
] {
  const dispatchAction = (data: string): void => {
    dispatch(updateChosenOrderComment(data));
  };

  const initComment = useAppSelector(
    (state) => state.orders.chosenOrder?.comments ?? ""
  );

  const [comment, setComment] = useState(initComment);

  const onTextAreaChagne = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setComment(event.target.value);
    console.log(comment);
    dispatchAction(event.target.value);
  };

  return [comment, onTextAreaChagne];
}
