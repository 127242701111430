import React from "react";

import ArrowLeft from "@components/Icons/ArrowLeft";
import Trash from "@components/Icons/Trash";
import Check from "@components/Icons/Check";

import PhotoControllerProps from "./PhotoControllerProps";

import usePhotoControllerFacade from "./usePhotoControllerFacade";

import "./PhotoController.scss";

const PhotoShowing: React.FC<PhotoControllerProps> = function (
  props
): JSX.Element {
  const { photos } = props;
  const [
    activePhoto,
    closePhotoController,
    deleteChosenPhoto,
    getActivePhotoClass,
    getOnImageClick,
    isPhotoConfirmed,
    confirmActivePhoto,
    stopEvent,
  ] = usePhotoControllerFacade(props);

  return (
    <div className="photo-controller">
      {activePhoto && (
        <img src={activePhoto} alt="doc" onContextMenu={stopEvent} />
      )}
      <button
        type="button"
        className="app-photo-btn btn-back"
        onClick={closePhotoController}
      >
        <ArrowLeft />
      </button>
      <button
        type="button"
        className="app-photo-btn btn-delete"
        onClick={() => deleteChosenPhoto()}
      >
        <Trash />
      </button>
      <div className="photo-controller-panel">
        <div className="images-block">
          {photos.length > 0 &&
            photos.map((photo, index) => (
              <img
                className={getActivePhotoClass(photo)}
                src={photo}
                key={Date.now() + Math.random()}
                alt="doc"
                onClick={getOnImageClick(index)}
                onContextMenu={stopEvent}
              />
            ))}
        </div>
        <button
          type="button"
          className={`app-photo-btn btn-confirm${
            isPhotoConfirmed(activePhoto) ? " confirmed" : ""
          }`}
          onClick={confirmActivePhoto}
        >
          <Check />
        </button>
      </div>
    </div>
  );
};

export default PhotoShowing;
