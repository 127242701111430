import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { shallowEqual } from "react-redux";

import { useAppSelector } from "@hooks/redux";
import {
  updateChosenOrganization,
  updateNewOrganization,
} from "@store/reducers/organization/Organizations";
import { dispatch } from "@store/index";
import { organizationAPI, customersAPI } from "@api/api";

export default function useNewCompanyFormFacade(): [
  string,
  string,
  string,
  (
    type: keyof Pick<IOrganization, "address" | "inn" | "name">
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  () => void,
  boolean,
  () => void,
  (event: React.ChangeEvent<HTMLInputElement>) => void,
  string | undefined
] {
  const id = useParams().id ?? undefined;
  const isNewCompany = !id;
  const chosenCompany = useAppSelector(
    (state) => state.organizations.chosenOrganization
  );
  const newCompany = useAppSelector(
    (state) => state.organizations.newOrganization
  );

  const [name, setName] = useState(
    isNewCompany ? "" : (chosenCompany?.name as string)
  );
  const [inn, setInn] = useState(
    isNewCompany ? "" : (chosenCompany?.inn as string)
  );
  const [adress, setAdress] = useState(
    isNewCompany ? "" : (chosenCompany?.address as string)
  );

  useEffect(() => {
    setName(chosenCompany?.name as string);
    setInn(chosenCompany?.inn as string);
    setAdress(chosenCompany?.address as string);
  }, [chosenCompany]);

  const dispatchAction = (
    payload: Partial<Pick<IOrganization, "address" | "inn" | "name">>
  ): void => {
    const actionCreator = isNewCompany
      ? updateNewOrganization
      : updateChosenOrganization;
    dispatch(actionCreator(payload));
  };

  const getOneInputChange =
    (type: keyof Pick<IOrganization, "address" | "inn" | "name">) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (type === "address") setAdress(event.target.value);
      if (type === "inn" && event.target.value.trim().length < 13)
        setInn(event.target.value.replace(/[^0-9.]/g, ""));
      if (type === "name") setName(event.target.value);
      const result = {} as Partial<
        Pick<IOrganization, "address" | "inn" | "name">
      >;
      result[type] = event.target.value;
      dispatchAction(result);
    };

  const onSaveBtnClick = (): void => {
    if (isNewCompany) organizationAPI.create(newCompany as INewOrganization);
    else organizationAPI.update(chosenCompany as IOrganization);
  };

  const onDeleteButtonClick = (): void => {
    organizationAPI.delete(Number(id));
  };

  const readFile = (file: File): void => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      console.log(fileReader.result);
      await customersAPI.importClients(fileReader.result as string, Number(id));
    };
    fileReader.readAsText(file, "utf-8");
  };

  const onImportClientInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    // const file = (await event.target.files?.item(0)?.text()) as string;
    if (!event.target.files?.item(0)) return;
    const file = event.target.files.item(0) as File;
    readFile(file);
  };

  return [
    name,
    inn,
    adress,
    getOneInputChange,
    onSaveBtnClick,
    isNewCompany,
    onDeleteButtonClick,
    onImportClientInputChange,
    id,
  ];
}
