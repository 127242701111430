import { useSelector, shallowEqual } from "react-redux";
import { store } from "../../store/index";

export default function useCompaniesFacade(): any[] {
  const companies = useSelector<
    ReturnType<typeof store["getState"]>,
    ReturnType<typeof store["getState"]>["organizations"]["organizations"]
  >((storeValue) => storeValue.organizations.organizations);
  const tableData = companies.map((item) => ({
    id: item.id,
    companyName: item.name,
    iin: item.inn,
    comlanyLocation: item.address,
  }));

  return [tableData];
}
