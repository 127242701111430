const endpoint = "main/task";

const preUrlDev = "http://localhost:9000/";

const preUrlPreprod = "https://dsa-app-preprod.mcb.ru/";

const currentPreUrls = {
  PREPROD: preUrlPreprod,
  DEVELOPMENT: preUrlDev,
};

export const redirectUri = `${
  currentPreUrls[process.env.ENVIRONMENT as keyof typeof currentPreUrls] +
  endpoint
}`;
