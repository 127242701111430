import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";

import { organizationAPI } from "@api/api";
import { useAppSelector } from "@hooks/redux";
import {
  updateNewOrder,
  updateChosenOrder,
} from "@store/reducers/order/OrderSlice";
import { dispatch } from "@store/index";

interface pickedDate {
  date: Date | null;
  inputValue: string;
}

export default function useClientFormGroupFormFacade(): [
  { label: string }[],
  { label: string }[],
  string | undefined,
  string,
  string,
  string,
  string,
  (newValue: any, actionMeta: any) => void,
  (newValue: any, actionMeta: any) => void,
  (event: pickedDate) => void,
  (event: pickedDate) => void
] {
  const id = useParams() ? useParams().id : undefined;
  const isNewOrder = !id;
  const state = useAppSelector((state) => state);
  const dispatchActions = isNewOrder ? updateNewOrder : updateChosenOrder;

  const organizations = useAppSelector(
    (state) => state.organizations.organizations
  );

  const chosenOrganization = useAppSelector(
    (state) => state.organizations.chosenOrganization
  );

  const chosenOrder = useAppSelector(
    (state) => state.orders.chosenOrder
  ) as IRequest;

  const orgSelectOptions = organizations.map(({ name }) => ({
    label: name,
  }));
  const [chosenOrg, setChosenOrg] = useState<string>();

  const [clientSelectOptions, setClientsSelectOptios] = useState<
    {
      label: string;
    }[]
  >([]);
  const [inn, setInn] = useState(isNewOrder ? "" : chosenOrder?.inn ?? "");
  const [fio, setFio] = useState(isNewOrder ? "" : chosenOrder?.fio);
  const [birthday, setBirthday] = useState(
    isNewOrder ? "" : chosenOrder?.birthdate?.split("-").reverse().join(".")
  );
  const [dateDepart, setDateDepart] = useState(
    isNewOrder
      ? new Date(Date.now())
          .toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join(".")
      : chosenOrder?.dateDepart?.split("-").reverse().join(".")
  );

  useEffect(() => {
    if (isNewOrder)
      dispatch(
        dispatchActions({
          dateDepart: new Date(Date.now()).toISOString().split("T")[0],
        })
      );
  }, []);

  useEffect(() => {
    const bd = chosenOrganization?.clients?.find(
      (client) => client.fio === fio
    )?.birthdate;
    console.log(bd);
    if (bd) {
      setBirthday(bd);
      dispatch(
        dispatchActions({ birthdate: bd.split(".").reverse().join("-") })
      );
    }
  }, [fio]);

  const requestOrganization = async (
    orderId: number
  ): Promise<IOrganization | undefined> => {
    if (!orderId) return undefined;
    const selectorChosenOrg = await organizationAPI.get(orderId);
    setChosenOrg(selectorChosenOrg?.name ?? "");
    setInn(selectorChosenOrg?.inn ?? "");
    setClientsSelectOptios(
      selectorChosenOrg?.clients?.map(({ fio }) => ({ label: fio })) ?? []
    );
    return selectorChosenOrg;
  };

  useEffect(() => {
    if (id)
      requestOrganization(
        organizations.find(
          (org) => org.name === chosenOrder?.organization?.name
        )?.id as number
      );
  }, []);

  const onChosenOrganizationChange = async (
    newValue: any,
    actionMeta: any
  ): Promise<void> => {
    const { label } = newValue;
    const orgId = Number(
      state.organizations.organizations.find((org) => org.name === label)?.id
    );
    const selectorChosenOrg = await requestOrganization(orgId);
    if (!selectorChosenOrg) return;
    dispatch(
      dispatchActions({
        organization: {
          name: selectorChosenOrg.name,
          inn: selectorChosenOrg.inn,
        },
      })
    );
  };

  const onFioInputChange = (newValue: any, actionMeta: any): void => {
    const fio = newValue.label;
    setFio(fio);
    dispatch(dispatchActions({ fio }));
  };

  const onBirthdayInputChange = (event: pickedDate): void => {
    const birthdate = event.inputValue;
    setBirthday(birthdate);
    dispatch(
      dispatchActions({ birthdate: birthdate.split(".").reverse().join("-") })
    );
  };

  const onDateDepartInputChange = (event: pickedDate): void => {
    const dateDepart = event.inputValue;
    setDateDepart(dateDepart);
    dispatch(
      dispatchActions({ dateDepart: dateDepart.split(".").reverse().join("-") })
    );
  };

  return [
    orgSelectOptions,
    clientSelectOptions,
    chosenOrg,
    fio,
    inn,
    birthday,
    dateDepart,
    onFioInputChange,
    onChosenOrganizationChange,
    onBirthdayInputChange,
    onDateDepartInputChange,
  ];
}
