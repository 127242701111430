import React, { useState, useCallback, useEffect } from "react";
import { useAppSelector } from "@hooks/redux";
import { updateChosenOrderDocComment } from "@store/reducers/order/OrderSlice";
import { dispatch } from "@store/index";
import debounce from "lodash.debounce";

export default function useDocCheckBlockFacade(
  docType: IAttach["documentType"]
): [
  Record<
    IAttach["documentType"],
    {
      title: string;
      filename: string;
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    }
  >,
  string
] {
  const dispatchAction = (
    type: IAttach["documentType"],
    value: string
  ): void => {
    dispatch(
      updateChosenOrderDocComment({
        documentType: type,
        comment: value,
      })
    );
  };

  const getInitDocCommentValue = (type: IAttach["documentType"]): string => {
    return useAppSelector(
      (state) =>
        state.orders.chosenOrder?.attache?.find(
          (item) => item.documentType === type
        )?.comment ?? ""
    );
  };
  const initComment = getInitDocCommentValue(docType);
  const [comment, setComment] = useState(initComment);

  const chosenOrder = useAppSelector((state) => state.orders.chosenOrder);

  const getDocTypeFilesLength = (type: IAttach["documentType"]): number => {
    return (
      chosenOrder?.attache?.find((doc) => doc.documentType === type)?.fileAttach
        ?.length ?? 0
    );
  };

  const getFilenameForDoctype = (type: IAttach["documentType"]): string => {
    return new Array(getDocTypeFilesLength(type))
      .fill(null)
      .map((_, index) => `Изображение_${index + 1}`)
      .join(", ");
  };

  const getOnInputChantgeListener =
    (type: IAttach["documentType"]) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (type === docType) setComment(event.target.value);
      dispatchAction(type, event.target.value);
    };

  // useEffect(() => {
  //   if (comment !== initComment) dispatchAction(docType, comment);
  // }, [comment]);

  // const fileItems: Record<
  //   IAttach["documentType"],
  //   {
  //     title: string;
  //     filename: string;
  //     onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //   }
  // > = {
  //   passport_main: {
  //     title: "Разврот паспорта",
  //     filename: getFilenameForDoctype("passport_main"),
  //     onChange: getOnInputChantgeListener("passport_main"),
  //   },
  //   passport_registration: {
  //     title: "Страница регистрации",
  //     filename: getFilenameForDoctype("passport_registration"),
  //     onChange: getOnInputChantgeListener("passport_registration"),
  //   },
  //   passport_previous: {
  //     title: "Предыдущие паспорта",
  //     filename: getFilenameForDoctype("passport_previous"),
  //     onChange: getOnInputChantgeListener("passport_previous"),
  //   },
  //   dbo_statement: {
  //     title: "Заявление ДБО",
  //     filename: getFilenameForDoctype("dbo_statement"),
  //     onChange: getOnInputChantgeListener("dbo_statement"),
  //   },
  //   // clients_photo: {
  //   //   title: "Фото клиента",
  //   //   filename: getFilenameForDoctype("clients_photo"),
  //   //   onChange: getOnInputChantgeListener("clients_photo"),
  //   // },
  //   another_document: {
  //     title: "Другой документ",
  //     filename: getFilenameForDoctype("another_document"),
  //     onChange: getOnInputChantgeListener("another_document"),
  //   },
  // };

  const getFileItems = (): Record<
    IAttach["documentType"],
    {
      title: string;
      filename: string;
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    }
  > => {
    const attacheDocTypes = chosenOrder?.attache.reduce(
      (acc, { documentType }) => {
        acc.push(documentType);
        return acc;
      },
      [] as DocumentTypes[]
    );

    const result = {} as Record<
      IAttach["documentType"],
      {
        title: string;
        filename: string;
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
      }
    >;

    attacheDocTypes?.forEach((exisDocType) => {
      result[exisDocType] = {
        title: "Другой документ",
        filename: getFilenameForDoctype(exisDocType),
        onChange: getOnInputChantgeListener(exisDocType),
      };
    });

    return result;
  };

  return [getFileItems(), comment];
}
