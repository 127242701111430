import React from "react";
import { Row, Col } from "react-bootstrap";
import RectButton from "mcb-uikit/RectButton/index";
import { dispatch } from "@store/index";
import { resetAllOrdersWithPagination } from "@store/reducers/order/OrderSlice";
import { resetAllOrganizations } from "@store/reducers/organization/Organizations";
import { keycloak } from "@utils/keycloakInstance";
import { useAppSelector } from "@hooks/redux";

import "./LogoutPanel.scss";

export const LogoutPanel: React.FC = function (): JSX.Element {
  const username = useAppSelector(({ user }) => user.name);
  return (
    <div className="logout">
      <Row>
        <Col className="name_wrapper">
          <p>{username}</p>
        </Col>
        <Col className="btn_logout_wrapper">
          <RectButton
            styleType="red"
            onClick={() => {
              dispatch(resetAllOrdersWithPagination());
              dispatch(resetAllOrganizations());
              keycloak.logout();
            }}
          >
            Выйти
          </RectButton>
        </Col>
      </Row>
    </div>
  );
};
