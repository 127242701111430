import React from "react";

import converOrderStatus from "@utils/convertOrderStatus";
import { RequestStatusBlockProps } from "./RequestStatusBlockProps";

import useRequestStatusBlockFacade from "./useRequestStatusBlock";

import "./RequestStatusBlock.scss";

export const RequestStatusBlock: React.FC<RequestStatusBlockProps> = function (
  props
): JSX.Element {
  const [status] = useRequestStatusBlockFacade();

  return (
    <p className="request-status-block">Статус: {converOrderStatus(status)}</p>
  );
};
