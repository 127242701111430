export enum UserRoles {
  admin = "dsa_administrator",
  supervizer = "dsa_supervisor",
  manager = "dsa_director",
  agent = "dsa_agent",
  agentPremium = "dsa_agentpremium",
}
export enum OrderStatus {
  draft = "draft",
  error = "error",
  verification = "verification",
  work = "work",
  success = "success",
  archive = "archive",
}
