import React from "react";
import { useNavigate } from "react-router-dom";
import RectButton from "mcb-uikit/RectButton/index";

import { ButtonsBlockProps } from "./ButtonsBlockProps";

import "./ButtonsBlock.scss";

export const ButtonsBlock: React.FC<ButtonsBlockProps> = function (
  props
): JSX.Element {
  const navigatation = useNavigate();
  const getOnClick =
    (
      onClick: ButtonsBlockProps["config"][number]["onClick"],
      navigateTo: string | undefined
    ) =>
    () => {
      if (onClick) onClick();
      if (navigateTo) navigatation(navigateTo, { replace: true });
    };
  return (
    <div className="buttons-block">
      {props.config.map(
        ({ label, styleType, onClick, navigateTo, disabled }) => (
          <div key={label}>
            <RectButton
              styleType={styleType ?? "red"}
              onClick={getOnClick(onClick, navigateTo)}
              disabled={disabled}
            >
              {label}
            </RectButton>
          </div>
        )
      )}
    </div>
  );
};
