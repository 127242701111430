import { AppDispatch } from "src/store";
import { IApplication } from "src/types/order";
import { IComment } from "src/types/comment";
import { useAppDispatch } from "@hooks/redux";
import { authError, authSuccess } from "@store/reducers/auth/AuthSlice";
import {
  setOrganizations,
  setChosenOrganization,
} from "@store/reducers/organization/Organizations";
import {
  setOrders,
  setChosenOrder,
  setNewOrder,
  setPaginationProperties,
} from "@store/reducers/order/OrderSlice";
import { dispatch, store, getState } from "@store/index";
import { OrderStatus } from "@appTypes/Enums";
import axios, { API_URL } from "./index";

export const authorizeAPI = {
  async auth(login: string, password: string): Promise<boolean> {
    return axios
      .post<IResponse<IUser>>("/authorize", { login, password })
      .then((response) => {
        if (response.data.message === "") {
          dispatch(authSuccess(response.data.result));
          return true;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e: Error) => {
        dispatch(authError(e.message));
        return false;
      });
  },

  async sendSMSCode(code: number, login: string): Promise<any> {
    return axios.post<IResponse<IUser>>("/SmsCodeRequest ", { code, login });
  },

  async getSMSCode(): Promise<any> {
    return axios.post<IResponse<IUser>>("/pushsms");
  },
};

export const organizationAPI = {
  async get(id: number): Promise<IOrganization> {
    const result = await axios
      .get<IResponse<IOrganization>>(`/organization/${id}`)
      .then((response) => {
        if (response.data.message === "") {
          dispatch(setChosenOrganization(response.data.result));
          return response.data.result;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
    return result;
  },

  async getAll(paginationProperties?: any): Promise<IResponse<IOrganizations>> {
    return axios
      .post<IResponse<IOrganization[]>>(
        "/organization",
        paginationProperties ??
          store.getState().organizations.organisationsPaginationProperties
      )
      .then((response) => {
        if (response.data.message === "") {
          dispatch(setOrganizations(response.data.result));
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
  async create(organization: INewOrganization) {
    return axios
      .post("/organization/create", organization)
      .then((response) => {
        if (response.data.message === "") {
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
  async update(organization: IOrganization) {
    return axios
      .post("/organization/Update", organization)
      .then((response) => {
        if (response.data.message === "") {
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
  async delete(id: number) {
    return axios
      .post(`/organization/delete/${id}`)
      .then((response) => {
        if (response.data.message === "") {
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
};

export const customersAPI = {
  async importClients(fileData: string, id: number) {
    return axios
      .post("/customers", { id, fileData })
      .then((response) => {
        if (response.data.message === "") {
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
};

export const applicationAPI = {
  async get(id: number): Promise<IRequest> {
    return axios
      .get<IResponse<IRequest>>(`/order/${id}`)
      .then((response) => {
        if (response.data.message === "") {
          dispatch(setChosenOrder(response.data.result));
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
  async getAll(paginationProperties?: OrderPaginationProperties) {
    return axios
      .post<IResponse<IRequest[]>>(
        "/order",
        paginationProperties ??
          store.getState().orders.orderPaginationProperties
      )
      .then((response) => {
        if (response.data.message === "") {
          if (response.data.result.length > 0)
            dispatch(setOrders(response.data.result));
          else {
            const { page } = getState().orders.orderPaginationProperties;
            dispatch(
              setPaginationProperties({ page: page > 1 ? page - 1 : 1 })
            );
          }
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
  async create() {
    return axios
      .get<IResponse<number>>("/order/create")
      .then((response) => {
        if (response.data.message === "") {
          dispatch(setNewOrder({ id: response.data.result }));
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
  async update(request: IEditableRequest) {
    if (!(request as any).id) return;
    await axios
      .post("/order/Update", { ...request })
      .then((response) => {
        if (response.data.message === "") {
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  },
  async coment(comment: IComment) {
    return axios
      .post("/order/comment", comment)
      .then((response) => {
        if (response.data.message === "") {
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
  async delete(id: number) {
    return axios
      .post<IResponse<undefined>>(`/order/delete/${id}`)
      .then((response) => {
        if (response.data.message === "") {
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => {
        return e;
      });
  },
  async verification(id: number) {
    return axios
      .post("/order/verification", { id })
      .then((response) => {
        if (response.data.message === "") {
          return response.data;
        }
        return Promise.reject(new Error(response.data.message));
      })
      .catch((e) => e);
  },
};

export const exportReportAPI = {
  async exportReport() {
    // const url = `${API_URL}report/create`;
    // const link = document.createElement("a");
    // link.href = url;
    // link.click();
    // link.remove();
    // window.open(`${API_URL}report/create`);
    return axios
      .get("/report/create", { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        window.open(url, "_blank");
      });
  },
};
