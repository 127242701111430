import { TableProps } from "@components/Table/TableProps";

export const tableHeaders: TableProps["headers"] = [
  {
    label: "ID Заявки",
    accessor: "",
  },
  {
    label: "ФИО Клиента",
    accessor: "",
  },
  {
    label: "Дата рождения",
    accessor: "",
  },
  {
    label: "Название организации",
    accessor: "",
  },
  {
    label: "ИНН организации",
    accessor: "",
  },
  {
    label: "ФИО Агента",
    accessor: "",
  },
  {
    label: "Статус заявки",
    accessor: "",
  },
  {
    label: "Дата выезда",
    accessor: "",
  },
  {
    label: "Дата и время последнего изменения",
    accessor: "",
  },
];
