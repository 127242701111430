import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

import { useAppSelector } from "@hooks/redux";
import { applicationAPI } from "@api/api";

export default function useRequestStatusFacade(): [IRequest, string, string] {
  const id = useParams().id ?? "";

  const chosenOrder = useAppSelector(
    (storeValue) => storeValue.orders.chosenOrder,
    shallowEqual
  );

  const [chosenOrderStatus, setChosenOrderStatus] = useState("");

  useEffect(() => {
    applicationAPI.get(Number(id));
  }, []);

  useEffect(() => {
    if (chosenOrder)
      setChosenOrderStatus(
        chosenOrder?.statuses[chosenOrder.statuses.length - 1]?.status
      );
  }, [chosenOrder]);

  return [chosenOrder as IRequest, chosenOrderStatus, id];
}
