import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authReducer from "./reducers/auth/AuthSlice";
import orderReducer from "./reducers/order/OrderSlice";
import organizationsReducer from "./reducers/organization/Organizations";
import userReducer from "./reducers/user/UserSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  orders: orderReducer,
  organizations: organizationsReducer,
  user: userReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export const store = configureStore({ reducer: rootReducer });
export const { dispatch, getState } = store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
