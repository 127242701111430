import { store, RootState } from "@store/index";

const isOrganizationFilled = (order: IRequest["organization"]): boolean => {
  let result = true;
  (Object.keys(result) as (keyof IRequest["organization"])[]).forEach((key) => {
    if (order[key] === "") result = false;
  });

  return result;
};

const commonDoc: CommonDocuments[] = ["passport_main", "dbo_statement"];

const residentDoc: ResidentDocuments[] = [
  "passport_registration",
  "passport_previous",
];

const notResidentDoc: NotResidentDocuments[] = [
  "visa",
  "migration_card",
  "residence_permit",
  "temporary",
];

const isAttacheFilled = (attache: IAttach[], isResident: boolean): boolean => {
  let result = true;

  commonDoc.forEach((doc) => {
    const attacheItem = attache.find((item) => item.documentType === doc);
    if (!attacheItem || attacheItem.fileAttach.length === 0) result = false;
  });

  if (isResident) {
    residentDoc.forEach((doc) => {
      const attacheItem = attache.find((item) => item.documentType === doc);
      if (!attacheItem || attacheItem.fileAttach.length === 0) result = false;
    });
  } else {
    const filledDocs = notResidentDoc
      .map((item) =>
        attache.find((attacheItem) => attacheItem.documentType === item)
      )
      .filter((item) => item !== undefined);
    if (filledDocs.length !== 1) result = false;
  }

  return result;
};

const verifyFields: (keyof IRequest)[] = [
  "fio",
  "id",
  "birthdate",
  "resident",
  "organization",
  "dateDepart",
  "attache",
];

export default function isOrderFilled(type: "new" | "chosen"): boolean {
  const { chosenOrder, newOrder } = (store.getState() as RootState).orders;

  const verifiedOrder = type === "new" ? newOrder : chosenOrder;

  if (!verifiedOrder) return false;

  let result = true;

  (Object.keys(verifiedOrder) as (keyof IRequest)[]).forEach((key) => {
    if (!verifyFields.includes(key)) return;
    if (
      key === "organization" &&
      !isOrganizationFilled(verifiedOrder.organization)
    )
      result = false;

    if (
      key === "attache" &&
      !isAttacheFilled(verifiedOrder.attache, verifiedOrder.resident)
    )
      result = false;
    if (verifiedOrder[key] === undefined || verifiedOrder[key] === "")
      result = false;
  });
  return result;
}
