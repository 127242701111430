import React from "react";
import { Form } from "react-bootstrap";
import { useAppSelector } from "@hooks/redux";

import { LogoutPanel } from "@components/LogoutPanel/LogoutPanel";
import { RequestStatusBlock } from "@components/RequestStatusBlock/RequestStatusBlock";
import { RequestStatuses } from "@components/RequestStatusBlock/RequestStatusBlockProps";
import { SupervizerBlock } from "@components/SupervizerBlock/SupervizerBlock";
import { RequestStatusLog } from "@components/RequestStatusLog/RequestStatusLog";
import { ClientTypeRadioGroup } from "@components/ClientTypeRadioGroup/ClientTypeRadioGroup";
import { ClientInfoFormGroup } from "@components/ClientInfoFormGroup/ClientInfoFormGroup";
import { DocumentsFormGroup } from "@components/DocumentsFormGroup/DocumentsFormGroup";
import { ButtonsBlock } from "@components/ButtonsBlock/ButtonsBlock";

import { NewRequestProps } from "./NewRequestProps";

import useNewRequestFacade from "./useNewRequstFacade";

import "./NewRequest.scss";

export const NewRequest: React.FC<NewRequestProps> = function ({
  type,
}): JSX.Element {
  const [chosenOrderStatus, chosenOrder, data, newButtonsConfig, changeData] =
    useNewRequestFacade();
  const superViserTitle = useAppSelector(
    (state) => state.orders.chosenOrder?.id.toString() ?? ""
  );

  const superViserTitleNew = useAppSelector(
    (state) => state.orders.newOrder?.id ?? ""
  );

  return (
    <div className={`new-request${type === "new" ? " new" : ""}`}>
      <span className="req-title">
        Новая заявка{" "}
        {type === "edit" ? `№${superViserTitle}` : `№${superViserTitleNew}`}
      </span>
      <LogoutPanel />
      <RequestStatusBlock status={chosenOrderStatus as RequestStatuses} />
      {data.comments && <SupervizerBlock name="" comment={data.comments} />}
      <RequestStatusLog />
      <div className="agent-info">
        <p>ФИО Агента</p>
        <p>{chosenOrder?.fioAgent}</p>
      </div>
      <Form className="form">
        <p className="form-title">Новый клиент</p>
        <ClientTypeRadioGroup />
        <ClientInfoFormGroup />
        <p className="form-doc-title">Документы</p>
        <DocumentsFormGroup />
        <ButtonsBlock config={newButtonsConfig} />
      </Form>
    </div>
  );
};
