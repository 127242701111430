/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import McbInput from "mcb-uikit/DefaultInput/index";

import { ButtonsBlock } from "@components/ButtonsBlock/ButtonsBlock";

import { NewCompanyFormProps } from "./NewCompanyFormProps";

import useNewCompanyFormFacade from "./useNewCompanyFormFacade";

import "./NewCompanyForm.scss";

export const NewCompanyForm: React.FC = function (): JSX.Element {
  const [
    name,
    inn,
    adress,
    getOnInputChange,
    onSaveBtnClick,
    isNewCompany,
    onDeleteButtonClick,
    onImportClientInputChange,
    id,
  ] = useNewCompanyFormFacade();
  return (
    <div className="new-company-form">
      <Form>
        <Form.Group>
          <Form.Label>Название организации</Form.Label>
          <McbInput value={name} onChange={getOnInputChange("name")} />
          <Form.Label>ИНН организация</Form.Label>
          <McbInput value={inn} onChange={getOnInputChange("inn")} />
          <Form.Label>Адрес организации</Form.Label>
          <McbInput value={adress} onChange={getOnInputChange("address")} />
        </Form.Group>
        {!isNewCompany && (
          <>
            <label htmlFor="file" className="client-import">
              Импортировать список клиентов
            </label>
            <input
              type="file"
              id="file"
              className="client-import-input"
              accept=".txt"
              onChange={onImportClientInputChange}
            />
          </>
        )}
        {!isNewCompany ? (
          <ButtonsBlock
            config={[
              {
                label: "Сохранить",
                onClick: onSaveBtnClick,
                navigateTo: "../../main/organization",
              },
              {
                label: "Удалить",
                styleType: "line",
                navigateTo: "../../main/organization",
                onClick: onDeleteButtonClick,
              },
              {
                label: "Назад",
                styleType: "line",
                navigateTo: `/company-watch/${id}`,
              },
            ]}
          />
        ) : (
          <ButtonsBlock
            config={[
              {
                label: "Сохранить",
                onClick: onSaveBtnClick,
                disabled:
                  !(inn && name && adress) ||
                  String(inn).length +
                    String(name).length +
                    String(adress).length <
                    9,
                navigateTo: "../main/organization",
              },
              {
                label: "Назад",
                styleType: "line",
                navigateTo: "../main/organization",
              },
            ]}
          />
        )}
      </Form>
    </div>
  );
};
