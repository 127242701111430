import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { applicationAPI } from "@api/api";
import { useAppSelector } from "@hooks/redux";
import { OrderStatus } from "@appTypes/Enums";

export default function useCheckRequestFacade(): [
  (value: boolean) => void,
  () => void,
  () => void
] {
  const id = useParams().id ?? "";

  const chosenOrder = useAppSelector(
    (state) => state.orders.chosenOrder,
    shallowEqual
  );

  const onHaveErrorBtnClick = (value: boolean): void => {
    if (value) {
      applicationAPI.coment({
        orderId: chosenOrder?.id as number,
        comments: chosenOrder?.comments as string,
        status: "error",
      });
    }
  };

  const onDeleteOrderBtnClick = (): void => {
    applicationAPI.delete(chosenOrder?.id as number);
  };

  const onSucessfulCompletedBtnClick = (): void => {
    applicationAPI.coment({
      orderId: chosenOrder?.id as number,
      comments: "Успешно проверено",
      status: OrderStatus.success,
    });
  };

  useEffect(() => {
    applicationAPI.get(Number(id));
  }, []);

  return [
    onHaveErrorBtnClick,
    onSucessfulCompletedBtnClick,
    onDeleteOrderBtnClick,
  ];
}
