import React, { useState, useEffect } from "react";

import PhotoControllerProps from "./PhotoControllerProps";

export default function usePhotoControllerFacade({
  photos,
  confirmedPhotos,
  setPhotos,
  setConfirmedPhotos,
  closeIt,
}: PhotoControllerProps): [
  string,
  () => void,
  () => void,
  (photo: string) => "chosen" | "",
  (index: number) => () => void,
  (photo: string) => boolean,
  () => void,
  (event: React.MouseEvent) => void
] {
  const [activePhoto, setActivePhoto] = useState<string>(photos[0]);
  const [innerConfirmedPhotos, setInnerConfirmedPhotos] = useState([
    ...confirmedPhotos,
  ]);

  const getOnImageClick = (index: number) => (): void =>
    setActivePhoto(photos[index]);

  const getActivePhotoClass = (photo: string): "chosen" | "" =>
    photo === activePhoto ? "chosen" : "";

  const isPhotoConfirmed = (photo: string): boolean => {
    if (innerConfirmedPhotos.length === 0) return false;
    return innerConfirmedPhotos.includes(photo);
  };

  const confirmActivePhoto = (): void => {
    const newConfirmedPhotos = innerConfirmedPhotos.includes(activePhoto)
      ? innerConfirmedPhotos.filter((photo) => photo !== activePhoto)
      : [...innerConfirmedPhotos, activePhoto];
    setInnerConfirmedPhotos(newConfirmedPhotos);
  };

  const deleteChosenPhoto = (): void => {
    const chosenPhotoIndex = photos.findIndex((item) => item === activePhoto);
    setPhotos(photos.filter((value) => value !== photos[chosenPhotoIndex]));
    if (innerConfirmedPhotos.includes(activePhoto))
      setInnerConfirmedPhotos(
        innerConfirmedPhotos.filter((photo) => photo !== activePhoto)
      );
    if (photos[chosenPhotoIndex + 1])
      return setActivePhoto(photos[chosenPhotoIndex + 1]);
    if (photos[chosenPhotoIndex - 1])
      return setActivePhoto(photos[chosenPhotoIndex - 1]);
    return setActivePhoto("");
  };

  const closePhotoController = (): void => {
    if (innerConfirmedPhotos.length) setConfirmedPhotos(innerConfirmedPhotos);
    closeIt();
  };

  useEffect(() => {
    if (photos.length < 1) closePhotoController();
  }, [photos]);

  const stopEvent = (event: React.MouseEvent): void => event.preventDefault();

  return [
    activePhoto,
    closePhotoController,
    deleteChosenPhoto,
    getActivePhotoClass,
    getOnImageClick,
    isPhotoConfirmed,
    confirmActivePhoto,
    stopEvent,
  ];
}
