import React from "react";

import { OrderStatus } from "@appTypes/Enums";

import { RequestTitle } from "@components/RequestTitle/RequestTitle";
import { LogoutPanel } from "@components/LogoutPanel/LogoutPanel";
import { RequestStatusBlock } from "@components/RequestStatusBlock/RequestStatusBlock";
import { SupervizerBlock } from "@components/SupervizerBlock/SupervizerBlock";
import { UserInfoBlock } from "@components/UserInfoBlock/UserInfoBlock";
import { ClientInfoBlock } from "@components/ClientInfoBlock/ClientINfoBlock";
import { RequestStatusLog } from "@components/RequestStatusLog/RequestStatusLog";
import { ClientDocBlock } from "@components/ClientDocBlock/ClientDocBlock";
import { ButtonsBlock } from "@components/ButtonsBlock/ButtonsBlock";
import { RequestStatuses } from "@components/RequestStatusBlock/RequestStatusBlockProps";

import useRequestStatusFacade from "./useRequestStatusFacade";

import { RequestStatusProps } from "./RequestStatusProps";

import { getButtonsBlockConfig } from "./config";

import "./RequestStatus.scss";

export const RequestStatus: React.FC<RequestStatusProps> = function (
  props?
): JSX.Element {
  const [chosenOrder, chosenOrderStatus, id] = useRequestStatusFacade();

  return (
    <div
      className={`request-status${
        chosenOrderStatus === OrderStatus.error ? "" : " without-superviser"
      }`}
    >
      <RequestTitle type="order" />
      <LogoutPanel />
      <RequestStatusBlock status={chosenOrderStatus as RequestStatuses} />
      {chosenOrderStatus === OrderStatus.error && (
        <SupervizerBlock
          name={chosenOrder?.lastCommentsAgent?.fio as string}
          comment={chosenOrder?.comments as string}
        />
      )}
      <RequestStatusLog />
      <UserInfoBlock />
      <div className="form">
        <p className="form-title">Новый клиент</p>
        <ClientInfoBlock />
        <p className="form-title">Документы</p>
        <ClientDocBlock labels={props.labels} />
        <ButtonsBlock config={getButtonsBlockConfig(id as string).needEdit} />
      </div>
    </div>
  );
};
