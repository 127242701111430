import React from "react";

interface IContextModal {
  children: React.ReactNode;
}

export const ContextMenuModal: React.FC<IContextModal> = function ({
  children,
  ...props
}): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <div>{children}</div>;
};
