import React from "react";
import McbInput from "mcb-uikit/DefaultInput/index";

import { DocCheckBlockProps } from "./DocCheckBlockProps";

import useDocCheckBlockFacade from "./useDocCheckBlock";

import "./DocCheckBlock.scss";

export const DocCheckBlock: React.FC<DocCheckBlockProps> = function ({
  type,
}): JSX.Element {
  const [fileItems, comment] = useDocCheckBlockFacade(type);
  return (
    <div className="doc-check-block" key={fileItems[type].title}>
      <span>{fileItems[type].title}</span>
      <span>{fileItems[type].filename}</span>
      <McbInput
        placeholder="Поле для ввода комментария"
        value={comment}
        onChange={fileItems[type].onChange}
      />
    </div>
  );
};
