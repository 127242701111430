import { useParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "@hooks/redux";
import { dispatch } from "@store/index";
import { resetAllOrganizations } from "@store/reducers/organization/Organizations";
import { ButtonsBlockProps } from "@components/ButtonsBlock/ButtonsBlockProps";

export default function useWatchCompanyFacade(): [
  string,
  string,
  string,
  string,
  ButtonsBlockProps["config"]
] {
  const { id } = useParams();
  const chosenOrganization = useAppSelector(
    ({ organizations }) => organizations.chosenOrganization
  );

  const role = useAppSelector((state) => state.user.role);
  const isAllowEditCompanies =
    role.includes("dsa_director") || role.includes("dsa_supervisor");

  const buttonsConfig: ButtonsBlockProps["config"] = [
    {
      label: "Редактировать",
      disabled: isAllowEditCompanies,
      navigateTo: `../../edit-company/${id}`,
    },
    {
      label: "Вернуться",
      onClick: () => dispatch(resetAllOrganizations()),
      navigateTo: "../../main/organization",
    },
  ];

  return [
    id as string,
    chosenOrganization?.name as string,
    chosenOrganization?.inn as string,
    chosenOrganization?.address as string,
    buttonsConfig,
  ];
}
