import React from "react";

import { ModalBackgroundProps } from "./ModalBackgroundProps";

import "./ModalBackground.scss";

export const ModalBackground: React.FC<
  React.PropsWithChildren<ModalBackgroundProps>
> = function ({ onClick, children, subClass }): JSX.Element {
  const onItClick = (event: React.MouseEvent): void => {
    if (
      !onClick ||
      !(event.target as HTMLElement).classList.contains("modal-background")
    )
      return;

    onClick();
  };
  return (
    <div
      className={`modal-background${subClass ? ` ${subClass}` : ""}`}
      onClick={onItClick}
      role="group"
    >
      {children}
    </div>
  );
};
