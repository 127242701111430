import React from "react";

import { useAppSelector } from "../../hooks/redux";

import "./ClientInfoBlock.scss";

export const ClientInfoBlock: React.FC = function (): JSX.Element {
  const chosenOrder = useAppSelector((state) => state.orders.chosenOrder);
  return (
    <div className="client-info-group">
      <div className="info-block">
        <p>ФИО Клиента</p>
        <p>{chosenOrder?.fio ?? ""}</p>
      </div>
      <div className="info-block">
        <p>Дата рождения</p>
        <p>{chosenOrder?.birthdate?.split("-").reverse().join(".") ?? ""}</p>
      </div>
      <div className="info-block">
        <p>Организация</p>
        <p>{chosenOrder?.organization?.name ?? ""}</p>
      </div>
      <div className="info-block">
        <p>ИНН организации</p>
        <p>{chosenOrder?.organization?.inn ?? ""}</p>
      </div>
      <div className="info-block">
        <p>Дата выезда</p>
        <p>{chosenOrder?.dateDepart?.split("-").reverse().join(".") ?? ""}</p>
      </div>
    </div>
  );
};
