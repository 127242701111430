import React, { ChangeEvent } from "react";
import { FormGroup, FormLabel } from "react-bootstrap";
import McbSelect from "mcb-uikit/MultiSelectReact/index";
import McbDateInput from "mcb-uikit/DateInputSingle/index";
import McbInput from "mcb-uikit/DefaultInput/index";
import { useAppSelector } from "../../hooks/redux";

import { ClientInfoFormGroupProps } from "./ClientInfoFormGroupProps";

import useClientFormGroupFormFacade from "./useClientFormGroupFacade";

import "./ClientInfoFormGroup.scss";

export const ClientInfoFormGroup: React.FC = function (): JSX.Element {
  const [
    orgSelectOptions,
    clientSelectOptions,
    chosenOrg,
    fio,
    inn,
    birthday,
    dateDepart,
    onFioInputChange,
    onChosenOrganizationChange,
    onBirthdayInputChange,
    onDateDepartInputChange,
  ] = useClientFormGroupFormFacade();
  return (
    <FormGroup className="client-info-group">
      <FormLabel>ФИО Клиента</FormLabel>
      <McbSelect
        value={{ label: fio }}
        options={clientSelectOptions}
        isMulti={false}
        name="clientName"
        onChange={onFioInputChange}
      />
      <FormLabel>Дата рождения</FormLabel>
      <McbDateInput
        value={birthday}
        onChange={onBirthdayInputChange}
        inputProps={{ readOnly: true }}
        datePickerProps={{ readOnly: true }}
      />
      <FormLabel>Организация</FormLabel>
      <McbSelect
        value={{ label: chosenOrg as string }}
        options={orgSelectOptions}
        isMulti={false}
        onChange={onChosenOrganizationChange}
        name="company"
      />
      <FormLabel>ИНН</FormLabel>
      <McbInput value={inn} name="iin" readOnly />
      <FormLabel>Дата выезда</FormLabel>
      <McbDateInput value={dateDepart} onChange={onDateDepartInputChange} />
    </FormGroup>
  );
};
