/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRoles } from "../../../types/Enums";

interface UserState {
  role: UserRoles | "";
  name: string;
  login: string;
}

const initialState: UserState = {
  role: "",
  name: "",
  login: "",
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserRole(state, action: PayloadAction<string>) {
      state.role = action.payload as UserRoles;
    },
    setUsername(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setUserLogin(state, action: PayloadAction<string>) {
      state.login = action.payload;
    },
  },
});

export const { setUserRole, setUsername, setUserLogin } = UserSlice.actions;

export default UserSlice.reducer;
