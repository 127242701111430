import React from "react";

import { RequestTitle } from "@components/RequestTitle/RequestTitle";
import { LogoutPanel } from "@components/LogoutPanel/LogoutPanel";
import { NewCompanyForm } from "@components/NewCompanyForm/NewCompanyForm";

import "./NewCompany.scss";

export const NewCompany: React.FC = function (): JSX.Element {
  return (
    <div className="new-company">
      <RequestTitle type="organization" />
      <LogoutPanel />
      <NewCompanyForm />
    </div>
  );
};
