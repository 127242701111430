import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import { MainFilterPanel } from "@components/MainFilterPanel/MainFilterPanel";
import { Table } from "@components/Table/Table";
import { MainSortPanel } from "@components/MainSortPanel/MainSortPanel";

import { useAppSelector } from "@hooks/redux";
import useCompaniesFacade from "./useCompaniesFacade";

import { tableHeaders } from "./TableConfig/TableHeaders";
import { TableData } from "./TableConfig/TableData";

import "./Companies.scss";

export const Companies: React.FC = function (): JSX.Element {
  const navigateTo = useNavigate();
  const role = useAppSelector((state) => state.user.role);
  const isAllowCreateCompanies =
    role.includes("dsa_director") || role.includes("dsa_supervisor");
  const [tableData] = useCompaniesFacade();
  return (
    <Container className="companies">
      <MainFilterPanel
        type="organizations"
        buttonsConfig={[
          {
            label: "Создать новую организацию",
            disabled: isAllowCreateCompanies,
            onClick: () => navigateTo("/new-company", { replace: true }),
          },
        ]}
      />
      <MainSortPanel componentOption="organizations" />
      <Table
        dataType="Company"
        headers={tableHeaders}
        data={tableData.map(
          (item: typeof tableData[number], index: number) => ({
            "#": item.id,
            companyName: item.companyName,
            iin: item.iin,
            comlanyLocation: item.comlanyLocation,
            id: item.id,
          })
        )}
      />
    </Container>
  );
};
