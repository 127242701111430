import React from "react";
import McbRadioButton from "mcb-uikit/RadioButton/index";

import useClientTypeRadioGroupFacade from "./useClientTypeRadioGroupFacade";

import "./ClientTypeRadioGroup.scss";

export const ClientTypeRadioGroup: React.FC = function (): JSX.Element {
  const [isResident, onRadioButtonChange] = useClientTypeRadioGroupFacade();
  return (
    <div className="client-type">
      <div className="resident">
        <McbRadioButton
          name="client-type"
          value="true"
          checked={isResident}
          onChange={onRadioButtonChange}
        />
        <span>Резидент</span>
      </div>
      <div className="not-resident">
        <McbRadioButton
          name="client-type"
          value=""
          checked={!isResident}
          onChange={onRadioButtonChange}
        />
        <span>Нерезидент</span>
      </div>
    </div>
  );
};
