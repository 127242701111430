import { useState } from "react";
import { organizationAPI } from "@api/api";
import { dispatch } from "@store/index";
import { setPaginationProperties } from "@store/reducers/order/OrderSlice";
import {
  setOrgPaginationProperties,
  OrganisationsPaginationProperties,
} from "@store/reducers/organization/Organizations";

export default function useMainSortPanel(
  componentOption: string
): [
  (newValue: any, actionMeta: any) => void,
  (newValue: any, actionMeta: any) => void,
  string,
  string,
  { label: string }[]
] {
  const dispatchAction = (
    value:
      | Partial<OrderPaginationProperties>
      | Partial<OrganisationsPaginationProperties>
  ): void => {
    if (componentOption === "orders") {
      dispatch(setPaginationProperties(value));
    } else if (componentOption === "organizations") {
      dispatch(setOrgPaginationProperties(value));
    }
  };

  const orderOptions: { label: string; value: string }[] = [
    { label: "ID", value: "order.id" },
    { label: "ФИО Клиента", value: "order.fio" },
    { label: "Название организации", value: "organization.name" },
    { label: "ИНН организации", value: "organization.inn" },
    { label: "ФИО Агента", value: "agent.fio" },
    { label: "Cтатус заявки", value: "status.status" },
    { label: "Дата выезда", value: "order.datedepart" },
    { label: "Дата и время последнего изменения", value: "status.date" },
  ];

  const organizationOptions: { label: string; value: keyof IRequest }[] = [
    { label: "ID", value: "id" },
    { label: "Наименование организации", value: "name" },
    { label: "ИНН", value: "inn" },
    { label: "Адрес", value: "address" },
  ];

  const keyLabels =
    componentOption === "orders"
      ? [
          { label: "ID" },
          { label: "ФИО Клиента" },
          { label: "Название организации" },
          { label: "ИНН организации" },
          { label: "ФИО Агента" },
          { label: "Дата выезда" },
          { label: "Дата и время последнего изменения" },
          { label: "Cтатус заявки" },
        ]
      : [
          { label: "ID" },
          { label: "Наименование организации" },
          { label: "Адрес" },
          { label: "ИНН" },
        ];

  const [choosenKey, setChoosenKey] = useState("ID");
  const [choosenType, setChoosenType] = useState("По убыванию");

  const setSortKey = (newValue: any, actionMeta: any): void => {
    setChoosenKey(newValue.label);
    const keyValue =
      componentOption === "orders"
        ? orderOptions.find((item) => item.label === newValue.label)
        : organizationOptions.find((item) => item.label === newValue.label);
    dispatchAction({ sortby: keyValue?.value as keyof IRequest });
  };

  const types = [
    { label: "По возрастанию", value: true },
    { label: "По убыванию", value: false },
  ];

  const setSortType = (newValue: any, actionMeta: any): void => {
    setChoosenType(newValue.label);
    const typeValue = types.find((item) => item.label === newValue.label);
    dispatchAction({ ascending: typeValue?.value });
  };

  return [setSortKey, setSortType, choosenKey, choosenType, keyLabels];
}
