const notResidentDocs: NotResidentDocuments[] = [
  "visa",
  "migration_card",
  "residence_permit",
  "temporary",
];

export default function getIsNotResidentDocFieldActive(
  fieldType: NotResidentDocuments,
  order: IRequest
): boolean {
  if (!fieldType || !order) return true;

  let result = true;

  notResidentDocs
    .filter((item) => item !== fieldType)
    .forEach((item) => {
      const length = order.attache.find(
        (attachItem) => attachItem.documentType === item
      )?.fileAttach?.length;

      if (length && length > 0) result = false;
    });

  return result;
}
