import { UserRoles } from "@appTypes/Enums";

const findUserRole = (rolesArray: Array<string>): string => {
  return (
    rolesArray.find((role: any) => Object.values(UserRoles).includes(role)) ||
    ""
  );
};

export default findUserRole;
