import { useState, useEffect } from "react";
import { useAppSelector } from "@hooks/redux";
import { useParams } from "react-router-dom";
import { shallowEqual } from "react-redux";

import {
  updateChosenOrderAttach,
  updateNewOrderAttach,
  deleteChosenOrderDoc,
  deleteNewOrderDoc,
} from "@store/reducers/order/OrderSlice";
import { dispatch } from "@store/index";
import getIsNotResidentDocFieldActive from "@utils/getIsNotResidentDocFieldActive";

type DocInfoContent = {
  fileLength: number;
  commnent: string;
};

type DocInfo = Record<DocumentTypes, DocInfoContent>;

export default function useDocumentFormGroupFacade(): [
  (type: DocumentTypes) => (data: string[]) => void,
  () => " comment" | "",
  DocInfo,
  (length: number) => string,
  (key: DocumentTypes) => () => void,
  boolean,
  () => void,
  (fieldType: NotResidentDocuments) => boolean
] {
  const isNewOrder = !useParams().id;
  const chosenOrder = useAppSelector(
    (state) => state.orders.chosenOrder,
    shallowEqual
  );

  const order = useAppSelector(
    ({ orders }) => (isNewOrder ? orders.newOrder : orders.chosenOrder),
    shallowEqual
  );
  const isResident = order?.resident as boolean;
  const updateActionCreator = isNewOrder
    ? updateNewOrderAttach
    : updateChosenOrderAttach;

  const getOrderAttachData = (type: DocumentTypes): DocInfoContent => {
    const defValue = {
      fileLength: 0,
      commnent: "",
    };
    if (!order) return defValue;
    const attache = order.attache.find((item) => item.documentType === type);
    if (!attache) return defValue;
    return {
      fileLength: attache.fileAttach.length,
      commnent: attache.comment,
    };
  };

  const dispatchActions = (type: DocumentTypes, payload: string[]): void => {
    dispatch(
      updateActionCreator({
        documentType: type,
        fileAttach: payload,
        comment: isNewOrder
          ? ""
          : chosenOrder?.attache?.find((item) => item.documentType === type)
              ?.comment ?? "",
      })
    );
  };

  const getDocInfo = (): DocInfo => {
    const attacheDocTypes = order?.attache.reduce((acc, { documentType }) => {
      acc.push(documentType);
      return acc;
    }, [] as DocumentTypes[]);
    const result = {} as DocInfo;
    attacheDocTypes?.forEach((type) => {
      result[type] = getOrderAttachData(type);
    });
    return result;
  };

  const [docInfo, setDocInfo] = useState<DocInfo>(getDocInfo());

  useEffect(() => {
    setDocInfo(getDocInfo());
  }, [order]);

  const getOnChangeForInput =
    (type: DocumentTypes) =>
    (data: string[]): void => {
      dispatchActions(type, data);
    };

  const getClassNameWithComment = (): " comment" | "" => {
    let result = false;
    const orderAttach = useAppSelector(({ orders }) => {
      const { newOrder, chosenOrder } = orders;
      return isNewOrder ? newOrder?.attache ?? [] : chosenOrder?.attache ?? [];
    });

    orderAttach.forEach((item) => {
      if (item && item.comment) result = true;
    });
    return result ? " comment" : "";
  };

  const getImageNames = (length: number): string => {
    if (!length) return "";
    return Array(length)
      .fill(null)
      .map((_, index) => `Изображение_${index + 1}`)
      .join(", ");
  };

  const getOnDeleteButtonClick = (key: DocumentTypes) => (): void => {
    const actionCreator = isNewOrder ? deleteNewOrderDoc : deleteChosenOrderDoc;
    dispatch(actionCreator(key));
  };

  const onAddAnotherDocumentButtonClick = (): void => {
    const anotherDocNumbers =
      order?.attache.reduce((acc, { documentType }) => {
        if (documentType.includes("another_document")) {
          const key = documentType.match(/\d{1,}$/);
          if (key) acc.push(Number(key[0]));
        }
        return acc;
      }, [] as number[]) ?? [];
    const newNumber =
      1 + (anotherDocNumbers.length > 0 ? Math.max(...anotherDocNumbers) : 0);
    dispatchActions(`another_document${newNumber}`, []);
  };

  const getIsNotResidentFieldActive = (
    fieldType: NotResidentDocuments
  ): boolean => getIsNotResidentDocFieldActive(fieldType, order as IRequest);

  return [
    getOnChangeForInput,
    getClassNameWithComment,
    docInfo,
    getImageNames,
    getOnDeleteButtonClick,
    isResident,
    onAddAnotherDocumentButtonClick,
    getIsNotResidentFieldActive,
  ];
}
